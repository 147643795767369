@import '/setting';
body{
    .budget-alert-note{
        p{
            margin: 0;
            padding: 2px;
            @include elementStyles($size: 11px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
            background: rgba(250,199,68,.62);  
            text-align: center;      
        }
    }
    .no-budget-access{
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        z-index: 99;
    }
    .budget-management-section{
        position: relative;
        background: #fbfbfb;
        .sidebar-menu{
            position: absolute;
            left: 0;
            top: 0;
            width: 150px;
            height: 100%;
            background: #f1f1f1;
            .menu-list{
                a{
                    display: inline-block;
                    width: 100%;
                    padding: 10px 15px;
                    border-bottom: 1px solid #d7d7d7;
                    @include elementStyles($size: 13px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);        
                    &.active{
                        background: #fbfbfb;
                        color: #3f51b5;
                    }
                }
            }
        }
        .budget-management-content{
            margin-left: 150px;
            .filter-cta{
                padding: 5px;
                .cta-list{
                    float: right;
                }
            }
            .expense-status{
                width: 80px;
                text-align: center;
                padding: 5px 0;
                border-radius: 20px;
                display: inline-block;
                color: #fff;
                @include elementStyles($size: 13px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
                &.pending{
                    background: #deb818;
                    color: #4f4100;
                }
                &.approved{
                    background: #0890dc;
                    color: #fff;
                }
                &.paid{
                    background: #43c20d;
                    color: #065d1a;
                }
                &.rejected{
                    background: #ff3838;
                    color: #300000;
                }
            }
        }
    }
    .expense-attachment-modal{
        width: 480px;
        .attachment{
            padding: 5px 15px;
            border-bottom: 1px solid #d7d7d7;
            ul{
                li{
                    cursor: pointer;
                    
                }
            }
        }
    }
}