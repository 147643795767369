@import '/setting';
// Sort Element Styles
.sort-section-list{
    border: 1px solid #d7d7d7;
    padding: 10px;
    h5{
        @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
    }
}
.sort-picture-item{
    &.inspection-picture{
        .dropped-pic-card{
            border: 1px solid #d7d7d7;
            border-bottom: none;
        }
        .MuiOutlinedInput-root{
            border-radius: 0;
        }
    }
    .dropped-pic-card{
        height: 200px;
        position: relative;
        .dropped-picture-cta{
            background: rgba(255,255,255,0.5);
            position: absolute;
            right: 0;
            top:0;
            padding: 3px;
            z-index: 10;
            cursor: pointer;
            span{
                cursor: pointer;
            }
        }
    }
}
// Modal & drawer Styling
.project-management-modal{
    .MuiInputBase-multiline{
        padding: 5px !important;
    }
    .MuiFormControl-marginNormal{
        margin-bottom: 0;
    }
    .MuiOutlinedInput-input{
        padding: 14px !important;
        @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400)        
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
        padding: 0;
    }
    .MuiTextField-root {
        label{
            @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
        }
    }
    .MuiDialogTitle-root{
        padding: 10px 24px;
        h2{
            @include elementStyles($size: 16px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
        }
    }
    .MuiDialogContent-root{
        padding: 10px 24px;
    }
    &.add-edit-work-order-modal{
        .work-order-inspection-date{
            margin-top: 10px;
            h4{
                @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500, $margin: 0 0 4px 0);
            }
        }
    }
    .MuiDialogActions-root{
        padding: 10px 24px;
    }
    .stages-table{
        td{
            vertical-align: top;
            }
            }
    .checklist-file-linking{
        h4{
            svg{
                margin-right: 4px;
            }
            span{
                @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
                cursor: pointer;
            }
        }
        .selected-files{
            background: #f0f0f0;
            h3{
                @include elementStyles($size: $font14, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
                padding: 8px 10px;
//                 border-bottom: 1px solid #d7d7d7;
            }
            .selected-files-blk{
                padding: 8px 10px;
                .file-list{
                    display: flex;
                    padding: 4px;
                    border-bottom: 1px solid #d7d7d7;
                    p{
                        @include elementStyles($size: 9px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
                    }
                }
                span.remove-file{
                    margin-left: auto;
                }
            }
        }
        .file-location{
            @include elementStyles($size: 9px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
            margin-left: 24px;
        }
        .PrivateSwitchBase-root-1{
            padding: 0 !important;
            float: right;
        }
        .linking-navigation{
            background: #f1f1f1;
            padding: 4px;
            margin-bottom: 10px;
        }
    }
    .drag-drop-cnt{
        min-height: 120px !important;
    }
    .t004-component-fields-modal{
        .component-fields-section{
            background: #f1f1f1;
            margin-bottom: 15px;
        }
        .fields-section-hd{
            padding: 10px;
            border-bottom: 1px solid #d7d7d7;
            display: flex;
            align-items: center;
            input{
                padding: 8px !important;
            }
            ul{
                margin-left: auto;
                li{
                    cursor: pointer;
                }
            }
        }
        .fields-section-body{
            padding: 10px;
            table{
                input{
                    padding: 8px !important;
                } 
            }
        }
    }
    .t004-import-template{
        .sparta-template{
            .inner-table{
                tr{
                    background: transparent;
                }
            }
        }
    }
}
.download-sample-temp{
    display: inline-flex;
    align-items: center;
    margin: 5px 0px 4px 0px;
    cursor: pointer;
    padding: 0px 3px;
    color: #0b518d;
    .MuiSvgIcon-root{
        font-size: 18px;
    }
    &:hover{
        background-color: #e8e8e8;
        border-radius: 3px;
        padding: 0px 3px;
    }
}
.section-sorting-modal{
    .MuiDialog-paperWidthSm{
     max-height: 380px;
    }
}

.add-edit-finding-drawer{
    box-shadow: 0 2px 2px rgba(0,0,0,5);
    .drawer-head{
        padding:10px 15px;
        border-bottom: 1px solid #d7d7d7;
        h4{
        @include elementStyles($size: 16px, $color: $black, $fontFamily: $ibmplexRegular);
        text-align: left;
        }
    }
    .drawer-body{
        padding: 15px;
    }
    .drawer-footer{
        border-top: 1px solid #d7d7d7;
        padding:10px 15px;
        ul{
        text-align: left;
        li{
            margin-right: 10px;
        }
        }
    }
}
.add-edit-project-modal{
    .project-other-info{
        border-top: 1px solid #d7d7d7;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
        .info-text{
            position: absolute;
            top: -15px;
            background: #fff;
            padding: 2px 10px 2px 10px;
            border: 1px solid #d7d7d7;
            border-radius: 15px;
        }
        .info-icon{
            cursor: pointer;
            position: absolute;
            right: 0;
            top: -15px;
            background: #fff;
            border: 1px solid #d7d7d7;
            border-radius: 50%;
            display: inline-block;
            width: 30px;
            height: 30px;
            text-align: center;
            svg{
                position: relative;
                top: 2px;
            }
        }
    }
    .project-contact{
        margin-bottom: 10px;
        h4{
            @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500, $margin: 0 0 10px 0);
            button{
                margin-left: auto;
            }
        }
    }
    .project-instructions{
        label{
            @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500, $margin: 0 0 10px 0);
        }
    }
}
.form-section-sorting-modal{
    width: 380px;
    min-height: 380px;
    .sort-section-list{
        border-left: 1px solid #d7d7d7;
        border-right: 1px solid #d7d7d7;
        border-bottom: 1px solid #d7d7d7;
        padding: 10px;
        &:first-child{
            border-top: 1px solid #d7d7d7;
        }
        h5{
            @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
        }
    }
}
.link-issues-modal{
    min-height: 320px;
    .issue-list{
        padding: 5px;
        border-bottom: 1px solid #d7d7d7;
        h5{
            @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
            cursor: pointer;
        }
        &:last-child{
            border-bottom: none;
        }
    }
    .issue-selected{
        border: 1px solid #d7d7d7;
        margin-bottom: 5px;
        h4{
            @include elementStyles($size: $font16, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
            padding: 5px;
        }
        .issue-list{
            clear: both;
            display: flex;
            h5{
                width: 80%;
            }
            svg{
                margin-left: auto;
                cursor: pointer;
            }
        }
    }
    .search-issue{
        position: relative;
        .issue-suggestion{
            position: absolute;
            max-height: 380px;
            overflow: auto;
            left: 0;
            top: 56px;
            right: 0;
        }
    }
}
.filter-ui-drawer{
    .filter-ui-drawer-header, .filter-ui-drawer-body, .filter-ui-drawer-footer{
        padding: 10px 15px;
    }
    .filter-ui-drawer-header{
        border-bottom: 1px solid #d7d7d7;
        h4{
            @include elementStyles($size: $font16, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
        }
    }
    .filter-ui-drawer-body{
        overflow: auto;
    }
    .filter-ui-drawer-footer{
        border-top: 1px solid #d7d7d7;
    }
}

.t003-document-sorting{
    border-bottom: 1px solid #d7d7d7;
    margin: 0;
    .drag-handler-blk{
        padding: 5px;
        display: flex;
        align-items: center;
        h4{
            @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular, $margin: 0 0 6px 0);
        }
        .question-notes{
            span{
                @include elementStyles($size: 12px, $color: #000000, $fontFamily: $ibmplexRegular);
            }
            @include elementStyles($size: 10px, $color: #000000, $fontFamily: $ibmplexLight);
            font-style: italic;
        }
    }
    ul.spacing-list{
        li{
            margin-right: 10px;
            span{
                @include elementStyles($size: 12px, $color: #3f51b5, $fontFamily: $ibmplexRegular);
                cursor: pointer;
            }
        }
    }
    .checklist-files{
        ul{
            li{
                border: 1px solid #d7d7d7;
                padding: 3px 40px 3px 8px;
                border-radius: 20px;
                position: relative;
                display: inline-block;
                .file-remove{
                    position: absolute;
                    right: 5px;
                    top: 20%;
                    cursor: pointer;
                    svg{
                        font-size: 15px;
                        cursor: pointer;
                    }
                }
                span.file-name{
                    @include elementStyles($size: $font11, $color: #000000, $fontFamily: $ibmplexRegular);
                    cursor: pointer;
                }
            }
        }
    }
}

.read-prerequisite-modal{
    padding: 10px 20px;
    ol{
        li{
            padding: 5px 0;
            @include elementStyles($size: 13px, $color: #000000, $fontFamily: $ibmplexRegular);
        }
    }
}
.add-attachment{
    display: inline-block;
    padding: 8px 20px;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    background: #3f51b5;
    color: #ffffff !important;
    @include elementStyles($size: 13px, $color: #fff, $fontFamily: $ibmplexRegular);
    input[type="file"]{
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
    }
}