@import '/setting';

body {
    .multiline-clamp{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .singleline-clamp{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .milestone-status, .stage-status{
        display: inline-block;
        width: 100px;
        padding: 3px 0px;
        border-radius: 15px;
        text-align: center;
        @include elementStyles($size: 13px, $color: $black, $fontFamily: $ibmplexRegular, $weight: 400);     
        span{
            justify-content: center;
        }
        &.active, &.open{
            color: #fff;
            background: #2b4ca7;
        }
        &.archived{
            color: #fff;
            background: rgb(89, 89, 89);
        }
        &.cancel{
            color: #fff;
            background: rgb(218, 50, 50);
        }
        &.completed, &.close{
            color: #fff;
            background: rgb(74, 201, 15);
        }
        &.in-progress{
            color: #fff;
            background: rgb(205, 171, 4);
        }
        &.reopen{
            color: #fff;
            background: rgb(163, 25, 228);
        }
        &.hold, &.on-hold{
            color: #fff;
            background: rgb(9, 134, 153);
        }
    }
    .tasks-management-section {
        position: relative;
        background: #fbfbfb;

        .sidebar-menu {
            position: absolute;
            left: 0;
            top: 0;
            width: 150px;
            height: 100%;
            background: #f1f1f1;

            .menu-list {
                a {
                    display: inline-block;
                    width: 100%;
                    padding: 10px 15px;
                    border-bottom: 1px solid #d7d7d7;
                    @include elementStyles($size: 13px, $color: $black, $fontFamily: $ibmplexMedium, $weight: 500);
                    &.active {
                        background: #fbfbfb;
                        color: #3f51b5;
                    }
                }
            }
        }
        .milestone-detail{
            margin-bottom: 10px;
            .milestone-task-issue-progress{
                clear: both;
                ul{
                    float: right;
                }
            }
            .milestone-asset-info{
                clear: both;
                ul{
                    float: right;
                }
            }
        }
        .tasks-management-content {
            margin-left: 150px;
            .filter-cta {
                padding: 5px;
                .cta-list {
                    float: right;
                }
            }
        }
        .task-management-detail{
            position: relative;
            overflow:'auto';
            .task-management-sidebar{
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
                width: 200px;
                background: #fff;
                overflow: auto;
                .task-management-sidebar-card{
                    padding: 4px 4px;
                    border-bottom: 1px solid #d7d7d7;
                    cursor: pointer;
                    &.active{
                        background: #f6f2f2;
                    }
                    h5{
                        @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexMedium, $weight: 500);
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                    p{
                        @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexRegular, $weight: 400);
                    }
                }
            }
            .back-to-list{
                cursor: pointer;
                margin-bottom: 5px;
                span{
                    @include elementStyles($size: $font15, $color: #3f51b5, $fontFamily: $ibmplexMedium, $weight: 500);
                }
            }
        }
        .task-detail-section{
            overflow: auto;
            margin-left: 215px;
            .task-management-tittle{
                ul {
                    margin:5px 0 10px 5px ;
                }
            }
           
        }
        .task-description-section{
            background-color: #f6f2f2;
            padding: 5px;
            margin: 20px 0;
            h6{
                @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexMedium, $weight: 500);
                padding: 5px 0;
            }
            p{
                padding: 10px;
            }
        } 
        .task-management-information{
            background-color: #f6f2f2;
            padding:15px;
            margin: 20px 0;
            h6{
                @include elementStyles($size: $font15, $color: $black, $fontFamily: $ibmplexMedium, $weight: 500);
                margin-bottom:10px;
            }
        } 
        .task-comment-box{
            .comment-content{
                font-size: $font15;
                font-family: $ibmplexRegular;
                p,span,li{
                    @include elementStyles($size: $font14, $color: $black, $fontFamily: $ibmplexRegular, $weight: 400);
                }
                h1,h2{
                    @include elementStyles($size: $font18, $color: $black, $fontFamily: $ibmplexMedium, $weight: 400);
                }
                h3,h4{
                    @include elementStyles($size: $font16, $color: $black, $fontFamily: $ibmplexMedium, $weight: 400);
                }
                h5,h6{
                    @include elementStyles($size: $font14, $color: $black, $fontFamily: $ibmplexMedium, $weight: 400);
                }
                ul,ol{
                    margin-left: 15px;
                }
            }
            h6{
                @include elementStyles($size: $font15, $color: $black, $fontFamily: $ibmplexMedium, $weight: 500);
                margin-bottom: 6px;
            }
            .edit-delete-comment{
                margin: 5px 0 10px 0px;
                li{
                    @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexRegular, $weight: 400);
                    border-right: 1px solid #d7d7d7;
                    padding-right: 8px;
                    cursor: pointer;
                    &:last-child{
                        border-right: none;
                    }
                    &:first-child{
                        cursor: default;
                    }
                }
            }
            .ckeditor-button{
                text-align: end;
            }
        }   
        .task-management-attachments{
            .attachment-blk{
                margin-bottom: 5px;
                .attachment-list{
                    margin-right: 10px;
                    border: 1px solid #d7d7d7;
                    margin-bottom: 10px;
                    .attachment{
                        position: relative;
                        .extension{
                            position: absolute;
                            top:50%;
                            left:50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    .file-info{
                        border-top: 1px solid #d7d7d7;
                        padding: 3px 1px;
                        span{
                            width: 80%;
                            @include elementStyles($size: $font11, $color: $black, $fontFamily: $ibmplexRegular, $weight: 400);
                            word-wrap: break-word;
                            white-space: pre-wrap;
                            word-break: break-word;
                        }
                        ul{
                            cursor: pointer;
                            margin-left: auto;
                            li{
                                margin: 0 !important;
                            }
                            svg{
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
            
        }
        .task-management-linked-issue{
            .linked-issue{
                border-bottom: 1px solid #d7d7d7;
                padding: 5px 10px;
                display: flex;
                h5{
                    @include elementStyles($size: $font15, $color: $black, $fontFamily: $ibmplexRegular, $weight: 400);
                    margin-bottom: 4px;
                }
                svg{
                    cursor: pointer;
                    margin-left: auto;
                }
            }
        }
    }
}