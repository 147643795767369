@import '../../../assets/styles/setting';

body{
  .info-text-secondary{
    font-size: 12px;
    background: #dfdfdf;
    padding: 18px;
    font-family: $ibmplexRegular;
    margin-bottom: 15px;
    border-radius: 3px;
  }
  .trial-expired-con{
    background: #fff;
     width: 100%;
     height: 100%;
     position: fixed;
     left: 70px;
     top: 45px;
  }
  .payment-modal{
    .modal-content{
      width: 80%;
      left: 10%;
      border-radius: 0px;
      .modal-header{
        border-bottom: none;
        padding-bottom: 0px;
        .modal-title{
          font-size: 20px;
        }
      }
      .modal-body{
        .fa-times-circle{
          color: red;
          font-size: 70px;
          margin-bottom: 20px;
        }
        a{
          font-size: 12px;
          color: #2882ef;
        }
        .cardsList{
          font-size: 14px;
          .inner-con{
            height: 360px;
            margin-bottom: 20px;
            overflow: auto;
            &::-webkit-scrollbar{
              display: none;
            }
            .card-list-item{
              cursor: pointer;
              margin-top: 10px;
              margin-left: 0px;
              margin-right: 0px;
              padding: 5px 10px;
              border: 1px solid #d3d3d3;
              border-radius: 5px;
              .card-number{
                text-transform: capitalize;
                span{
                  font-size: 16px;
                  span{
                    display: block;
                    font-size: 12px;
                    margin-top: -5px;
                  }
                }
              }
              .expiry-date{
                padding-top: 10px;
              }
              .col-md-2{
                padding-right: 0px;
                padding-left: 0px;
              }
              .col-md-6{
                padding-left: 0px;
              }
              img{
                width: 40px;
                margin-top: 5px;
                border: 1px solid #d3d3d3;
                border-radius: 5px;
              }
            }
            .active{
              border: 1px solid #007bff;
              font-weight: bold;
              border-radius: 5px;
              .fa-check-circle{
                display: block;
                font-size: 20px;
                color: #007bff;
                padding-top: 10px;
                float: right;
              }
              .fa-pencil-square-o{
                display: block;
                font-size: 15px;
                color: #007bff;
                padding-top: 10px;
                float: right;
              }
            }
          }
          .btn-primary{
            width: 100%;
          }
        }
      }
    }
  }
  .payment-con{
    .payment-card{
      margin-bottom: 30px;
      .StripeElement{
        height: 40px;
        border: 1px solid #d3d3d3;
        border-radius: 3px;
        padding: 10px;
      }
    }
    .add-new{
      width: 100%;
    }
    p{
      font-size: 12px;
      color: #d3d3d3;
      text-align: center;
      margin-top: 20px;
    }
    input{
      height: 40px;
      border: 1px solid #d3d3d3;
      border-radius: 3px;
      padding: 10px;
    }
    .margin-bottom-20{
      margin-bottom: 20px;
    }
  }
}


@keyframes slidein {
  from{
    width:40px
  }
  to{
    width: 240px
  }
}

@keyframes activeSlideOut {
  0%{
    width:40px;
  }
  10%{
    width:50px;
  }
  30%{
    width:60px;
  }
  60%{
    width:70px;
  }
  95%{
    width:70px;
  }
  100%{
    width: 230px;
    overflow: auto;
  }
}
@keyframes slideOut {
  from{
    width:240px
  }
  to{
    width: 40px
  }
}
@keyframes showHeadingBlock {
  from{
    opacity: 0;
  }
  to{
    opacity: 0.5;
  }
}
@keyframes hideHeadingBlock {
  from{
    opacity: 0.5;
  }
  to{
    opacity: 0;
  }
}
@keyframes showBlock {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@keyframes hideBlock {
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}

@media (min-width: 1200px) {

body{

  .scrollbar-hidden{
    &::-webkit-scrollbar{
      display: none;
    }
  }

  .inifinity-bottom{
    margin-bottom:70px;
  }

 .trial-expired-con{
   background: #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 70px;
    top: 45px;
 }


  .sideBar-block{
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    z-index: 3;
    transition: all 0.5s ease;
    width: 70px;
    &.expand-block{
      text-align: center;
      .prySidebarExp{
        overflow: visible;
        z-index: 0;
        width: 70px;
        -webkit-box-shadow: 5px 0 10px -5px rgba(0,0,0,.61);
        -moz-box-shadow: 5px 0 10px -5px rgba(0,0,0,.61);
        box-shadow: 5px 0 10px -5px rgba(0,0,0,.61);

        >div{
          width:100px;
        }
        .sideBarHeading{
          -webkit-animation: showHeadingBlock 0.5s forwards !important;
        }
        .sideBarSubheading{
          display: block;
          position: relative;
          left: 0px;
        }
		.dashboard-block{
			a{
				&.active{
				  width:100%;
				}
			}
		}


      }
      .click-indicator{
        visibility: visible !important;
        opacity: 1 !important;
        transition: visibility 0s linear 100ms, opacity 100ms !important;
    }
      a{
        &.active{
          -webkit-animation: activeSlideOut 0s forwards !important;

        }
      }
      .manage-users-link{
        width: 230px !important;
        overflow: auto;
        &::-webkit-scrollbar{
          display: none;
        }
      }

    }
    .prySidebarExp{
      position: absolute;
      top:0;
      bottom:0;
      text-align: center;
      width: 100px;
      .click-indicator{
        position: absolute;
        right: -26px;
        top: 40%;
        z-index: 0;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
        img{
          width:40px;
        }
      }
      .manage-users-link{
        position: fixed;
        bottom: 10px;
        left: 5px;
        padding: 5px;
        // right:0;
        // overflow: hidden;
        width: 40px;
        height: 38px;
        overflow: hidden;
        .sideBarSubheading{
          opacity:0;
          display: block;
          position: relative;
          left: 0px;
        }
      }
      &:hover{

        .click-indicator{
            visibility: visible;
            opacity: 1;
            transition: visibility 0s linear 100ms, opacity 100ms;

        }
      }
      >div{
        position: absolute;
      }
      .hidden-heading{
        opacity: 0 !important;
      }
      .sideBarHeading{
        font-family: $ibmplexRegular;
        opacity:0.5;
        font-size:14px;
        color:$color_fff;
        margin-bottom: 13px;
        -webkit-animation: hideBlock 0.2s forwards;
        margin-left:9px;
      }
      .sideBarSubheading{
        font-family: $ibmplexRegular;
        font-size:11px;
        color:$color_fff;
        display: block;
        position: relative;
        left: 0px;
        margin-top: 3px;
      }
    }
    .prySidebarExp{
      width: 70px;
      background:#6b5eb0;
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      // overflow:hidden;
      z-index: 1;

      >div{
        width:auto;
        transition: width 0.2s linear 0.5s;
        z-index: 0;
        transform: translateY(-50%);
        left:0;
        overflow: auto;
        &::-webkit-scrollbar{
          display: none;
        }
        &.dashboard-block{
          top:53%;
          height: 90%;
          width:100%;
          overflow: auto;
          &::-webkit-scrollbar{
            display: none;
          }
          >ul{
            padding:0px 0px 90px;
            // border-right:1px solid #2f7aff;
          }
          ul{
            li{
              position: relative;
              a{
                display: block;
                width: 100%;
                margin: 0 auto;
                padding: 8px 5px;
                &.active{
                  background: rgba(0,0,0,0.2);
                  border-radius: 2px;
                }
              }
            }
          }
        }
        .utility-block{
          margin-top: 20px;
        }
      }
      img{
          object-fit: contain;
      }
    }
  }
}
.seperator-line{
  border-top:1px solid #ddd;
  padding-top:15px;
  }
}


@media (max-width: 767px) {
  .contract-container{
    position:relative;
    .highter {
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #FB5353;
      cursor: pointer;
      box-shadow: 0 0 0 0 rgba(251,83,83,0.5);
      animation: pulse 2s infinite;
     }
    .mobile-bread-crumb{
      top: 15px;
      position: absolute;
      right: 5px;
    }
  }

  #ham{
    display: inline-block;
    cursor: pointer;

    span{
      cursor: pointer;
      display: block;
      position: static;
      width: 30px;
      height: 2px;
      background-color: black;
      margin-bottom: 4px;
      transition: .3s;
      &:last-of-type{
        margin-bottom: 0;
      }
      &.cont-breadcrumb-bar{
        width:22px;
        margin:0 0 4px auto;
      }
    }
    &.ham-toggle{
      span{
        &:first-of-type{
          transform: translateY(7px) rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:last-of-type {
          transform: translateY(-5px) rotate(45deg);
        }

      }
    }
  }
  .mobile-header{
    padding: 0 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,.5);
    height: 40px;
    display: block !important;
    padding-top: 3px;
    >div{
      display: block !important;
      padding: 0 10px;
    }
    .header-logo-search{
      li{
        padding: 2px !important;
        margin: 0;
        display: inline-block;
        border: none !important;
        vertical-align: middle;
        &.lessor-info{
          padding-right: 30px !important;
          img{
            height: 25px;
            max-width: 70px;
          }
        }
        .Select{
          text-align: left;
          top: -6px;
          height: 30px;
        }
        ul{
          li{
            padding: 0 7px !important;
          }
        }
      }
    }
  }

  .sideBar-block{
    position: fixed;
    z-index: 9;
    background: #fff;
    left: 0px;
    height:100%;
    display:block;
    top: 40px;
    overflow: hidden;
    transition: all 0.5s ease;
    // -webkit-animation: slideOut 0.5s  forwards ; /* Safari 4.0 - 8.0 */
    background: #6b5eb0;
    overflow-y: auto;
    padding-bottom: 40px;
    .prySidebarExp{
      overflow-y: auto;
      width: 60px;
      &::-webkit-scrollbar{
        display: none;
      }

    }
    .click-indicator{
      img{
        display: none;
      }
    }
    &.expand-block{
       left:0;
        overflow: visible;
        width: 75%;
        min-width: 150px;
        -webkit-box-shadow: 5px 0 10px -5px rgba(0,0,0,.61);
        -moz-box-shadow: 5px 0 10px -5px rgba(0,0,0,.61);
        box-shadow: 5px 0 10px -5px rgba(0,0,0,.61);
        .prySidebarExp{
          width: 70px;
          overflow: auto;
          &::-webkit-scrollbar{
            display: none;
          }
        }
        // .manage-users-link{
        //   width: 230px;

        // }

    }

    .dashboard-block{
      padding: 5px;
      h6{
        color:$color_fff;
        opacity: 0.5;
        font-size: 14px;
        margin-bottom:10px;
      }
      ul{
        li{
          margin-bottom:15px;
          a{
            color:$color_fff;
            font-size: 10px;
            display: block;
            text-align: center;
            img{
              width:15px;
              display: inline-block;
              margin-right:6px;
              &.logout-icon{
                width:11px;
              }
            }
          }
          &.utility-block{
            margin-top:20px;
          }
        }
      }
    }
  }
}

@media(min-width: 768px) and (max-width: 991px){
  header{
    display: none;
  }
  .mobile-header{
    padding: 0 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,.5);
    height: 40px;
    display: block !important;
    padding-top: 3px;
    >div{
      display: block !important;
      padding: 0 10px;
    }
    .header-logo-search{
      li{
        padding: 2px !important;
        margin: 0;
        display: inline-block;
        border: none !important;
        vertical-align: middle;
        &.lessor-info{
          padding-right: 30px !important;
          img{
            height: 25px;
            max-width: 70px;
          }
        }
        .Select{
          text-align: left;
          top: -6px;
          height: 30px;
        }
        ul{
          li{
            padding: 0 7px !important;
          }
        }
      }
    }
  }

  .sideBar-block{
    position: fixed;
    z-index: 9;
    background: #fff;
    left: 0px;
    height:100%;
    display:block;
    top: 40px;
    overflow: hidden;
    transition: all 0.5s ease;
    // -webkit-animation: slideOut 0.5s  forwards ; /* Safari 4.0 - 8.0 */
    background: #6b5eb0;
    overflow-y: auto;
    padding-bottom: 40px;
    .prySidebarExp{
      overflow-y: auto;
      width: 60px;
      &::-webkit-scrollbar{
        display: none;
      }

    }
    .click-indicator{
      img{
        display: none;
      }
    }
    &.expand-block{
       left:0;
        overflow: visible;
        width: 75%;
        min-width: 150px;
        -webkit-box-shadow: 5px 0 10px -5px rgba(0,0,0,.61);
        -moz-box-shadow: 5px 0 10px -5px rgba(0,0,0,.61);
        box-shadow: 5px 0 10px -5px rgba(0,0,0,.61);
        .prySidebarExp{
          width: 70px;
          overflow: auto;
          &::-webkit-scrollbar{
            display: none;
          }
        }
        // .manage-users-link{
        //   width: 230px;

        // }

    }

    .dashboard-block{
      padding: 5px;
      h6{
        color:$color_fff;
        opacity: 0.5;
        font-size: 14px;
        margin-bottom:10px;
      }
      ul{
        li{
          margin-bottom:15px;
          a{
            color:$color_fff;
            font-size: 10px;
            display: block;
            text-align: center;
            img{
              width:15px;
              display: inline-block;
              margin-right:6px;
              &.logout-icon{
                width:11px;
              }
            }
          }
          &.utility-block{
            margin-top:20px;
          }
        }
      }
    }
  }
}

@media(min-width: 992px) and (max-width: 1200px){

  header{
    display: none;
  }

  .mobile-header{
    padding: 0 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,.5);
    height: 40px;
    display: block !important;
    padding-top: 3px;
    >div{
      display: block !important;
      padding: 0 10px;
    }
    .header-logo-search{
      li{
        padding: 2px !important;
        margin: 0;
        display: inline-block;
        border: none !important;
        vertical-align: middle;
        &.lessor-info{
          padding-right: 30px !important;
          img{
            height: 25px;
            max-width: 70px;
          }
        }
        .Select{
          text-align: left;
          top: -6px;
          height: 30px;
        }
        ul{
          li{
            padding: 0 7px !important;
          }
        }
      }
    }
  }

  .sideBar-block{
    position: fixed;
    z-index: 9;
    background: #fff;
    left: 0px;
    height:100%;
    display:block;
    top: 40px;
    overflow: hidden;
    transition: all 0.5s ease;
    // -webkit-animation: slideOut 0.5s  forwards ; /* Safari 4.0 - 8.0 */
    background: #6b5eb0;
    overflow-y: auto;
    padding-bottom: 40px;
    .prySidebarExp{
      overflow-y: auto;
      width: 60px;
      &::-webkit-scrollbar{
        display: none;
      }

    }
    .click-indicator{
      img{
        display: none;
      }
    }
    &.expand-block{
       left:0;
        overflow: visible;
        width: 75%;
        min-width: 150px;
        -webkit-box-shadow: 5px 0 10px -5px rgba(0,0,0,.61);
        -moz-box-shadow: 5px 0 10px -5px rgba(0,0,0,.61);
        box-shadow: 5px 0 10px -5px rgba(0,0,0,.61);
        .prySidebarExp{
          width: 70px;
          overflow: auto;
          &::-webkit-scrollbar{
            display: none;
          }
        }
        // .manage-users-link{
        //   width: 230px;

        // }

    }

    .dashboard-block{
      padding: 5px;
      h6{
        color:$color_fff;
        opacity: 0.5;
        font-size: 14px;
        margin-bottom:10px;
      }
      ul{
        li{
          margin-bottom:15px;
          a{
            color:$color_fff;
            font-size: 10px;
            display: block;
            text-align: center;
            img{
              width:15px;
              display: inline-block;
              margin-right:6px;
              &.logout-icon{
                width:11px;
              }
            }
          }
          &.utility-block{
            margin-top:20px;
          }
        }
      }
    }
  }
}

 /* IE10+ specific styles go here */

 @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {


  .sideBar-block{
    width: 70px;
    &.expand-block{
      .prySidebarExp{
        width:70px;
        .sideBarHeading, .sideBarSubheading{
           opacity: 1 !important;
        }
        &:hover{
          .sideBarHeading, .sideBarSubheading{
            opacity: 1 !important;
          }
        }
      }
    }
    .prySidebarExp{
      width:70px;
      // .sideBarHeading, .sideBarSubheading{
      //   opacity: 0 !important;
      // }
      // &:hover{
      //   .sideBarHeading, .sideBarSubheading{
      //     opacity: 0 !important;
      //   }
      // }
    }
  }
}

.react-viewer-mask{
  background: rgba(70, 68, 68, 0.96)!important;
}

.file-preview-block{
  .shared-hellobar{
    position: fixed;
    top: 0px;
    padding: 2px 0px;
    text-align: center;
    width: 100%;
    background: #2f8cff;
    font-size: 14px;
    color: #fff;
    left: 0px;
    z-index: 99;
    // border: 1px solid #d4bb73;
    span{
      float: right;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .shared-view-valid{
    position: fixed;
    top: 95px;
    padding: 2px 0px;
    text-align: center;
    width: 100%;
    background: #fff3cd;
    font-size: 14px;
    color: #856404;
    left: 0px;
    z-index: 99;
    border: 1px solid #d4bb73;
  }
  .file-navigation{
    padding: 4px 26px;
    left: 0;
    right: 0;
    top: 38px;
    height: auto !important;
    h3{
      margin-bottom:3px;
    }
    .navigation-item{
      display: inline-block;

      .file-path{
        top:0 !important;
        left:0;
        display: inline-block;


      }
    }
  }
}
.timeline-container{
  border-radius: 10px;
  -webkit-box-shadow: 13px 10px 50px -10px rgba(0,0,0,0.14);
-moz-box-shadow: 13px 10px 50px -10px rgba(0,0,0,0.14);
box-shadow: 13px 10px 50px -10px rgba(0,0,0,0.14);
}



.application-sw-dropdown{
   display: inline-block;
  //  margin-left: 10px;
   background: #fff !important;
   color: #000 !important;
   border-radius: 5px;
   -webkit-box-shadow: 13px 10px 50px -10px rgba(0,0,0,0.36);
   -moz-box-shadow: 13px 10px 50px -10px rgba(0,0,0,0.36);
   box-shadow: 13px 10px 50px -10px rgba(0,0,0,0.36);
   ul{
     li{
       margin-right: 0 !important;
       padding: 3px 5px;
       border-bottom: 1px solid #d7d7d7;
       background: #eaeaea;
       cursor: pointer;
       &:first-child{
         border-top: 1px solid #d7d7d7;;
       }
     }
   }
}
