@import '../../../../assets/styles/setting';

body{
  .modal-dialog{
    .modal-content{
      .modal-body{
        .drop-zone-area{
          .content-drop-blk{
            border: 2px dashed #c4c4c4;
            height: 265px;
            position: relative;
            input{
              top: 50%!important;
              left: 50%!important;
              transform: translate(-50%, -50%);
              cursor: pointer;
            }
            div{
              position: absolute;
              top: 50% !important;
              left: 50% !important;
              transform: translate(-50%, -50%);
              width: 100%;
              text-align: center;
              p{
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
  .technical-inspection-form-v1{
    margin-left: 70px;
    padding: 100px 0 30px;
    .t011-header-count{
      margin: 10px;
      margin-top: 20px;
      .folder-card{
        background: #ffffff;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        margin: 0 0 5px;
        .folder-info{
          font-family: $ibmplexRegular;
          font-weight: 600;
          padding: 5px;
          &.vl{
            padding-left: 10px;
            &.color-blue{
              border-left: 10px solid #043BFC;
              color: #043BFC;
            }
            &.color-orange{
              border-left: 10px solid #67a0ff;
              color: #67a0ff;
            }
            &.color-green{
              border-left: 10px solid #49e6cf;
              color: #49e6cf;
            }
            &.color-brown{
              border-left: 10px solid #b99897;
              color: #b99897;
            }
            h4{
              font-size: 30px;
              font-weight: 500;
            }
            p{
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
    .tabs-header-blk{
      margin-top: 20px;
      .left-blk{
        h4{
          @include elementStyles($size: 20px, $color: #BD10E0, $fontFamily: $ibmplexSemiBold);
        }
      }
      .right-blk{
        text-align: right;
        ul{
          float: right;
          border: 1px solid #3f51b5;
          border-radius: 3px;
          position: relative;
          li{
            padding: 5px 15px 5px;
            border-right: 1px solid #3f51b5;
            cursor: pointer;
            &:last-child{
              border-right: none;
            }
            &.list-inline-item:not(:last-child){
              margin-right: auto;
            }
            &.active{
              background: #DBEAFB;
              color: #043BFC;
            }
            &.inactive-bg{
              background: #DAD9D9;
            }
            img{
              width: 18px;
            }
          }
        }
        .refresh-icon{
          width: 18px;
          cursor: pointer;
        }
        .right-border{
          border-right: 1px solid #c4c4c4;
          vertical-align: middle;
          display: inline-block;
          height: 32px;
          margin: 0px 15px;
        }
      }
    }
    .free-text-seach-blk{
      position: relative;
      img{
        cursor: pointer;
        &.search-icon{
          position: absolute;
          top: 10px;
          left: 10px;
        }
        &.close-icon{
          position: absolute;
          top: 10px;
          right: 15px;
        }
      }
    }
    .bulk-operation-menu-blk{
      background: #c4c4c4;
      padding: 10px;
      .MuiButton-text{
        @include elementStyles($size: 13px, $color: #000000, $fontFamily: $ibmplexRegular);
        text-transform: capitalize;
        background-color: #ffffff;
        border-radius: 20px;
        padding: 3px 10px;
      }
    }
    .charts-blk{
      .user-stats{
        .MuiPaper-root{
          height: 300px ;
          overflow: auto;
        }
      }
    }
    .table-ui-component{
      position: relative;
      margin-top: auto;
      overflow: auto;
      .table-height{
        background: none;
        // height: auto!important;
        overflow: unset;
        table{
          &.table-sticky{
          // display: block;
          overflow-x: auto;
        }
        &.table-sticky-list{
          height: 100%;
        }
          thead{
            background: #DFE8ED;
            tr{
              z-index: 1;
              position: -webkit-sticky;
              position: sticky;
              th{
                color: #6D9BB9;
                padding: 6px;
                border-right: 1px solid #D1CFCF;
                background: #DFE8ED;
                z-index: 99;
                &.table-head,&.sub-head{
                  border-right: none;
                  &:last-child{
                    border-right: none;
                  }
                }
                span{
                  cursor: default;
                }
              }
            }
          }
          tbody{
            background: none;
            tr{
              &:nth-child(odd){
                background-color: #EBEFF6;
                color: #000000;
              }
              &:nth-child(even){
                background-color: #F5F7FA;
                color: #000000;
              }
              td{
                border-right: 1px solid #D1CFCF;
                padding: 5px 8px;
              }
            }
            .no-rcrd-found-tbody{
              .no-records-found{
                &.no-records-found-t011-v1{
                  width: 100%!important;
                }
              }
            }
          }

          .form-group-edit{
            textarea{
              background: transparent;
            }
          }
          .react-datepicker__input-container{
              input[type="text"]{
                background: transparent;
              }
            }
        }
      }
      textarea{
        background: transparent;
        word-break: break-word;
        width: 100%;
      }
    }
    .table-header{
      margin-top : 5px;
      h4{
        font-family: $ibmplexRegular;
        font-size: 18px;
        color: #BD10E0;
      }
      .form-group{
        width: 275px;
        margin-bottom: 6px;
        .Select-value-label{
          color: #c4c4c4!important;
          font-size: 14px;
        }
        &.enable-select-clear{
          .Select-control{
            .Select-clear{
              display: block;
            }
          }
        }
      }
      .Select-control{
        .Select-value-label{
          color: #BD10E0 !important;
        }
        .Select-clear{
            display: none;
          }
      }
      .delete-prs{
        display: inline-block;
        position: relative;
        top: -18px;
        margin-left: 15px;
        border-radius: 3px;
        li{
          &:last-child{
            border-right: none;
          }
          padding: 5px 5px;
          margin-right: auto;
          border-left: 1px solid #3f51b5;
          span{
            color: #3f51b5;
            font-size: 14px;
            img{
              width: 15px;
              cursor: pointer;
              margin-right: 5px;
              vertical-align: sub;
            }
          }
        }
      }
      .full-screen-block{
        float: right;
        ul{
          li{
            &:first-child{
              width: auto;
              font-size: 14px;
              font-weight: 700;
              color: #3f51b5;
            }
            &.disposition{
              width: 130px;
              margin-right: auto;
            }
            width: 130px;
            margin-bottom: 10px;
            .Select-control{
              .Select-value-label{
                font-size: 14px;
              }
              .Select-clear{
                display: block;
              }
            }
          }
        }
      }
    }
    .custom-select-block{
      .form-group{
        margin-bottom: auto;
      }
      .Select-control{
        .Select-multi-value-wrapper{
          .Select-placeholder{
            padding-left: 10px;
          }
        }
        .Select-arrow-zone{
          top: 3px;
        }
        .Select-clear{
          display: none;
        }
      }
    }
    .dropable-zone{
      border: 2px dashed #717171;
      margin-bottom: 10px;
    }
    .drop-excel{
      padding: 50px 80px;
      .sample{
        font-size: 16px;
        text-align: right;
        margin-bottom: 5px;
        a{
          color: #3f51b5;
          font-weight: 600;
        }
      }
      .notice-info{
        margin-left: auto;
      }
    }
    .display-tabs{
      position: absolute;
      right: 52px;
      ul{
        border: 1px solid #3f51b5;
        border-radius: 3px;
        li{
          padding: 2px 15px 5px;
          border-right: 1px solid #3f51b5;
          cursor: pointer;
          &:last-child{
            border-right: none;
          }
          &.list-inline-item:not(:last-child){
            margin-right: auto;
          }
          &.active{
            background: #DBEAFB;
            color: #043BFC;
          }
          &.inactive-bg{
            background: #DAD9D9;
          }
          img{
            width: 18px;
          }
        }
      }
    }
    .application-edit-side-bar{
      .edit-bar-content-blk{
        .body-content{
          position: unset;
          .sidebar-form{
            form{
              .form-group{
                .tech-sum-item{
                  .custom-select-block{
                    .Select-control{
                      border: none;
                      border-radius: 0;
                      border-bottom: 1px solid #717171;
                      .Select-clear{
                        display: none;
                      }
                    }
                  }
                }
              }
            }
            .MuiInputBase-input{
              height:auto;
              border-bottom: none;
            }
          }
        }
        .top-content{
          .Select-control{
            .Select-clear{
              display: none;
            }
          }
        }
      }
    }
    .technical-edit-sidebar{
      .top-content{
        background: #f0f2f3;
      }
      .body-content{
        .submit-block{
          left: 0;
        }
        .Select-control{
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #cccccc;
          .Select-placeholder{
            font-size: 14px;
            padding-left: 0;
          }
        }
        .form-footer{
          position: fixed;
          bottom: 0;
          padding:  10px 20px;
          background: #f0f2f3;
          width: 100%;
        }
        .form-group{
          &.enable-select-clear{
            .Select-control{
              .Select-clear{
                display: block;
              }
            }
          }
        }
      }
    }
    .list-header{
      margin: 5px 0 15px;
      .right-blk{
        float: right;
        li{
          vertical-align: middle;
          span{
            @include elementStyles($size: 13px, $color: #000000, $fontFamily: $ibmplexRegular);
            img{
              cursor: pointer;
              &.delete-icon{
                width: 16px;
                margin-right: 5px;
              }
            }
          }
          button{
            span{
              color: #ffffff;
            }
          }
        }
      }
      .left-blk{
        .list-inline{
          .list-inline-item{
            margin-right: 8px;
            &.issues-menu{
              .MuiButton-label{
                text-transform: capitalize;
                font-size: 16px;
                color:#3f51b5;
              }
              .MuiTouchRipple-root{
                text-transform: capitalize;
              }
              .MuiMenuItem-root{
                font-size: 14px;
              }
              .MuiButton-contained{
                padding-left: 0;
                box-shadow: none;
                background-color: transparent;
              }
            }
            .total-count{
              @include elementStyles($size: 13px, $color: #000000, $fontFamily: $ibmplexSemiBold);
              padding: 6px 10px;
              box-shadow: 0px 2px 1px -1px rgb(0, 0, 0, 0.2), 0px 1px 1px 0px rgb(0, 0, 0, 0.14), 0px 1px 3px 0px rgb(0, 0, 0, 0.12);
              border-radius: 4px;
              margin-right: 8px;
            }
            input{
              font-size: 13px;
              min-width: 300px;
              padding: 6px 10px;
              box-shadow: 0px 2px 1px -1px rgb(0, 0, 0, 0.2), 0px 1px 1px 0px rgb(0, 0, 0, 0.14), 0px 1px 3px 0px rgb(0, 0, 0, 0.12);
              border-radius: 4px;
            }
          }
        }
      }
    }
    .issue-detail{
      margin-top: -320px;
      z-index: 999;
      position: relative;
      background: rgba(245, 245, 245);
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
      margin-left: -75px;
      .left-block{
        background: #FFFFFF;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        height:100%;
        width:24%;
        z-index: 999;
        overflow: auto;
        position: fixed;
        word-break: break-all;
        margin-top: -12px;
        .issue-head{
          font-size: 18px;
          color: #BD10E0;
          border-bottom: 1px solid #C4C4C4;
          padding: 20px 15px;
        }
        .list-wrapper{
          border-radius: 4px;
          cursor: pointer;
          li{
            border-bottom: 1px solid #C4C4C4;
            box-sizing: border-box;
            &.active{
              border-left: 3px solid rgba(255, 100, 0);
              background: rgba(255, 247, 243);
              .issue-desc{
                font-family: $ibmplexSemiBold;
                color: #000000;
              }
            }
            &:hover{
              background: rgba(255, 247, 243);
              .issue-desc{
                color: rgba(255, 100, 0);
              }
            }
            .list-wrapper-detail{
              @include elementStyles($size: 13px, $color: #000000, $fontFamily: $ibmplexRegular);
              padding: 15px 25px;
              .issue-no{
                font-family: $ibmplexRegular;
                font-size: 14px;
                &.open-issue{
                  color: #67a0ff;
                }
                &.reopen-issue{
                  color: #b99897;
                }
                &.closed-issue{
                  color: #49e6cf;
                }
              }
              .issue-desc{
                font-size: 14px;
                word-break: break-all;
              }
              .assignee{
                font-family: $ibmplexRegular;
                color:#555555;
                margin-top: 10px;
                span{
                  font-family: $ibmplexRegular;
                }
              }
            }
          }
        }
      }
      .right-block{
        .issue-head-blk{
          .issue-desc-head{
            position: relative;
            input[type="text"]{
              @include elementStyles($size: 18px, $color: #000000, $fontFamily: $ibmplexSemiBold);
              padding: 5px 13px;
              width: 90%;
              height:40px;
              &:hover, &:active, &:focus{
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                border-radius: 4px;
                padding: 5px 13px;
              }
              &:focus{
                border: 1px solid #c4c4c4;
              }
            }
            .close-icon,.delete-icon{
              position: absolute;
              right: 15px;
              margin-top: 12px;
              cursor: pointer;
              &.close-icon{
                top: 2px;
                width: 16px;
              }
              &.delete-icon{
                margin-right: 30px;
              }
            }
          }
          ul{
            li{
              padding: 5px 6px;
              margin-right: auto;
              &:first-child{
                padding-left: 15px;
              }
              .right-border{
                font-size: 8px;
                border-right: 1px solid rgba(0, 0, 0, 0.54);
                margin-right: 15px;
                vertical-align: middle;
              }
              .attachment-count{
                @include elementStyles($size: 12px, $color: #000000, $fontFamily: $ibmplexLight);
                margin-left: 3px;
              }
              .added-by{
                @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
              }
            }
          }
        }
        .desc-detail{
          @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
          word-break: break-all;
        }
        .MuiTypography-body1{
          @include elementStyles($size: 16px, $color: #000000, $fontFamily: $ibmplexSemiBold);
          img{
            &.edit-icon{
              margin-left: 8px;
              position: relative;
              bottom: 2px;
            }
          }
        }
        .MuiTabs-root{
          margin-bottom: 8px;
        }
        .MuiTab-root{
          font-size: 16px;
          text-transform: capitalize;
        }
        .comments-attachment-blk{
          border-bottom: 1px dashed #f2f2f2;
          margin-bottom: 20px;
          .comments-blk{
            margin: 10px 0 3px;
            li{
              @include elementStyles($size: 15px, $color: #000000, $fontFamily: $ibmplexSemiBold);
              .lessee-type{
                background: #B57428;
                @include elementStyles($size: 10px, $color: #ffffff, $fontFamily: $ibmplexRegular);
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
                border-radius: 4px;
                width: 120px;
              }
            }
          }
          .comments{
            @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
            word-break: break-all;
            padding: 8px 0 25px;
            margin-left: 50px;
          }
        }
        .attachments-blk{
          margin: 10px 0 30px;
          background: #ffffff;
          li{
            margin: 10px ;
            position: relative;
            img{
              width: 100%;
              height: 200px;
              border: 1px solid #ccc;
              &.delete-icon{
                position: absolute;
                cursor: pointer;
                bottom: 5px;
                right: 25px;
                width: 11px;
                height: auto;
                margin-right: 5px;
                border: none;
                opacity: 1;
              }
            }
            .download-icon{
              position: absolute;
              cursor: pointer;
              bottom: 5px;
              right: 3px;
              width: 18px;
              height: auto;
              border: none;
              opacity: 1;
              img{
                width: 18px;
                height: auto;
                border: none;
              }
            }
            .file-preview-cta{
              .file-name{
                @include elementStyles($size: 13px, $color: #000000, $fontFamily: $ibmplexRegular);
              }
            }
            // &:hover{
            //   img{
            //     &.delete-icon{
            //       transition: all;
            //       opacity: 1;
            //     }
            //     &.download-icon{
            //       transition: all;
            //       opacity: 1;
            //     }
            //   }
            // }
          }
        }
        P{
          &.date-time{
            @include elementStyles($size: 13px, $color: #555555, $fontFamily: $ibmplexLight);
            margin-left: 50px;
            margin-bottom: 8px;
            .edit-delete-cta{
              margin-left: 15px;
              img{
                cursor: pointer;
              }
            }
          }
        }
        .other-info{
          padding: 15px;
          .other-info-head{
            @include elementStyles($size: 18px, $color: #000000, $fontFamily: $ibmplexRegular);
          }
        }
        input{
          height: auto;
        }
      }
    }
    .MuiTable-root {
      .MuiTableHead-root{
        tr{
          th{
            @include elementStyles($size: 13px, $color: #6d9bb9, $fontFamily: $ibmplexSemiBold);
            background-color: #fafafa;
            padding: 12px;
            z-index: 1;
          }
        }
      }
      .MuiTableBody-root{
        tr{
          &.listing-row{
            cursor: pointer;
            .flex-centered{
              .issue-desc{
                .issue-no{
                  font-family: $ibmplexSemiBold;
                  &.open-issue{
                    color: #67a0ff;
                  }
                  &.reopen-issue{
                    color: #b99897;
                  }
                  &.closed-issue{
                    color: #49e6cf;
                  }
                }
                span{
                  p{
                    display: inline;
                  }
                }
              }
            }
          }
          td{
            @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
            padding: 8px 12px;
            white-space: nowrap;
            overflow: hidden;
            .issue-desc{
              width: 400px;
            }
          }
          &:nth-child(odd){
            background: #ebeff6;
          }
          &:nth-child(even){
            background: #f5f7fa;
          }
        }
      }
    }
    .issues-listing-blk{
      .status-reopen, .status-close, .status-open{
        display: inline-block;
        text-align: center;
        background: #b99897;
        color: #ffffff;
        border-radius: 20px;
        padding: 3px 10px;
        width: 70px
      }
      .status-close{
        background: #49e6cf;
      }
      .status-open{
        background: #67a0ff;
      }
    }
    .charts-blk{
      .graph-head{
        @include elementStyles($size: 16px, $color: #000, $fontFamily: $ibmplexSemiBold);
        margin-bottom: 5px;
      }
    }
  }
  .techMsn-switcher{
    top: 42px;
  }
  .form-header{
    .select-form-block{
      .dropdown-menu{
        height: 435px!important;
      }
    }
  }
  .popper-box{
    padding: 10px;
    margin-top: 5px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);;
    border-radius: 4px;
    p{
      padding: 3px;
      @include elementStyles($size: 14px, $color: #000, $fontFamily: $ibmplexLight);
      span{
        margin-left: 15px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  body{
    .techMsn-switcher{
      .heading-block{
        border-left: none;
      }
    }
    .modal{
      .modal-dialog{
        min-width: 400px;
        .modal-content{
          .modal-body{
            .drop-zone-area{
              .content-drop-blk{
                p{
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
    .technical-inspection-form-v1{
      padding-top: 430px;
      .form-header{
        top: 208px;
        .select-form-block{
          width: 100%;
        }
        .export-block{
          .download-block{
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }
      }
      .tabs-header-blk{
        .left-blk{
          h4{
            font-size: 14px;
          }
        }
        .right-blk{
          .refresh-icon{
            width: 15px;
          }
          ul{
            li{
              padding: 2px 6px 3px;
              img{
                width: 15px;
              }
            }
          }
          .right-border{
            margin: 0 10px;
          }
        }
      }
      .list-header{
        .left-blk{
          .list-inline{
            .list-inline-item{
              margin-bottom: 3px;
              input{
                margin-bottom: 3px;
              }
              &.issues-menu{
                .MuiButton-label{
                  font-size: 14px;
                }
              }
            }
          }
        }
        .right-blk{
          float: none;
        }
      }
      .bulk-operation-menu-blk{
        .MuiButton-text{
          padding: 3px 6px;
        }
        .list-inline{
          .list-inline-item{
            margin: 0px 3px 5px;
            img{
              width: 12px;
            }
          }
        }
      }
      .issue-detail{
        margin-top: -545px;
        .left-block{
          display: none;
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  body{
    .form-header{
      top: 130px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  body{
    .form-header{
      top: 130px;
    }
    .technical-inspection-form-v1{
      padding-top: 230px;
      .list-header{
        .left-blk{
          .list-inline{
            .list-inline-item{
              margin-right: 4px;
              input{
                min-width: 250px;
              }
            }
          }
        }
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  body{
    .form-header{
      top: 130px;
    }
    .technical-inspection-form-v1{
      margin-left: 35px;
      padding-top: 190px;
      .list-header{
        .left-blk{
          .list-inline{
            .list-inline-item{
              margin-right: 4px;
              input{
                min-width: auto;
              }
            }
          }
        }
      }
    }
  }
}
