@import '/setting';
body{
    .hotspot-hovering{
        background: #264D1C !important;
    }
    .sortableHelper{
        z-index: 10000 !important;
    }
    .tui-image-editor-container{
        margin: 0 auto;
        .tui-image-editor-header-logo{
            display: none;
        }
        .tui-image-editor-header-buttons{
            display: none !important;
        }
    }
    .full-w-drag-drop-zone{
        min-height: 100px !important;
        .MuiDropzoneArea-text{
            margin: 10px 0 !important;
            @include elementStyles($size: 16px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
        }
        svg{
            width: 40px;
            height: 40px;
        }
    }
    .empty-collection-ui{
        text-align: center;
        padding: 40px 0;
        h4{
            @include elementStyles($size: $font18, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
        }
    }
    .drag-drop-cnt{
        &.small-blk{
            min-height: 100px !important;
            padding: 15px;
            width: 100%;
            p{
            font-size: $font15;
            margin: 0 0 10px 0;
            }
        }
    }
    .page-loader{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 9999;
        background: rgba(0,0,0,0.1);
        overflow: hidden;
        cursor: not-allowed;
        span{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img{
                width: 100px;
            }
        }
    }
    .delete-record-modal{
        text-align: center;
        padding: 30px;
        h4{
            @include elementStyles($size: $font18, $color: #000000, $fontFamily: $ibmplexRegular, $margin: 0 0 40px 0);
        }
        .actions-cta{
            button{
                margin-left: 10px;
                &.first-child{
                margin-left: 0;
                }
            }
        }
    }
    .project-status, .project-status-bar{
        display: inline-block;
        text-align: center;
        width: 100px;
        font-size: $font13;
        border-radius: 3px;
        font-family: $ibmplexMedium;
        font-weight: 500;
        padding:3px 0;
        &.cancelled{
            background: #F5C7CA;
            color:#622439
        }
        &.draft{
            background: #EBD186;
            color: #7F5E0D;
        }
        &.initiated{
            background: #acacac;
            color: #000000;
        }
        &.launched{
            background: #7b8bff;
            color: #fff;
        }
        &.in-review{
            background: #573C7F;
            color: #D0BEEE;
        }
        &.completed{
            background: #93c49f;
            color: #264D1C;
        }
        &.approved{
            background: #63eac9;
            color: #264D1C;
        }
        &.archived{
            background: #9a9991;
            color: #264D1C;
        }
    }
    .project-basic-detail{
        padding: 5px 0;
        .project-back-info{
            a{
                float: left;
            }
            h4{
                @include elementStyles($size: $font16, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
            }
            ul{
                li{
                    @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
                }
            }
        }

        ul.project-currency{
            float: right;
            li{
                margin-right: 5px !important;
                padding-right: 5px;
                border-right: 1px solid #d7d7d7;
                &:last-child{
                    border-right: 0;
                }
                @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
            }
        }
    }
    .label-value-card{
        p{
            @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
            margin-bottom: 2px;
        }
        h6{
            @include elementStyles($size: $font15, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
        }
    }
    .revision-history-drawer{
        .history-head{
            border-bottom: 1px solid #d7d7d7;
            display: flex;
            padding: 10px;
            h4{
                @include elementStyles($size: $font16, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
            }
            span{
                margin-left: auto;
                cursor: pointer;
            }
        }
    }
    .edit-revision-history{
        margin-bottom: 15px;
        padding: 20px 20px 20px 30px;
        overflow: auto;

        h6,p{
            font-size: 12px;
            margin-bottom: 5px;
        }
        ul{
            border-left: 1px solid #8b36e1;
            padding-left: 20px;
            li{
                position: relative;
                .timeline-icon{
                    position: absolute;
                    left: -35px;
                    top: 0;
                    z-index: 10;
                    img{
                        width: 27px;
                        background: #ffffff;
                    }
                }
                background: #fff;
                box-shadow: 0 4px 16px 0 #e6e6e6;
                border-radius: 6px;
                padding: 15px;
                margin-bottom: 20px;
                table{
                    width: 100%;
                }
            }
        }
        .edit-detail-table{
            overflow: auto;
            table{
                width: 100%;
                border-collapse: separate;
                thead{
                    th{
                        padding:4px 20px;
                        font-family: $ibmplexRegular;
                        font-size:12px;
                        color:#a0a0a0;
                        border:1px solid #ccc;
                        width:80px !important;
                    }
                }
                tbody{
                    th{
                        padding:4px 20px;
                        font-family: $ibmplexRegular;
                        font-size:12px;
                        color:#a0a0a0;
                        border:1px solid #ccc;
                        width:80px !important;
                    }
                    td{
                        width:80px !important;
                        padding:4px 20px;
                        font-family: $ibmplexRegular;
                        font-size:12px;
                        color:#000000;
                        border:1px solid #ccc;
                        width:80px !important;
                        span{
                            color:#000000 !important;
                        }

                    }
                }
            }
        }
    }
}
model-viewer {
      cursor: grab;
      display: flex;
      height: 80vh;
      width: 100%;
      overflow: hidden;
      position: relative;
      user-select: none;
      background-color:#849bf1eb;
    }
/* hotspot styling */
  .hotspot{
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: none;
    background-color: blue;
    color: #fff;
    box-sizing: border-box;
//     pointer-events: none;
  }
   .existing-hotspot{
   background-color: red;
   color: #fff;
   }

  .hotspot.selected{
    background-color: red;
  }
  .hotspot:not(.selected) > .annotation{
    display: none;
  }
