body{
    @media(max-width: 767px){
        .tracker-block{
            .title-block{
                margin-left: 0;
                margin-top: 15px;
                margin-bottom: 15px;
            }
            .project-heading{
                margin-left: 0;
                margin-right: 0;
                li{
                    a{
                        padding: 8px 6px;
                    }
                }
            }
            .add-bulk-operations{
                margin: 0 !important;
                .status-details{
                    img{
                        bottom: 2px;
                    }
                }
            }
            .project-tracker-block, .project-range-block{
                margin: 0 !important;
            }
            .item-detail-block{
                width: auto;
                display: inline-block;
                white-space: nowrap;
                .innerheading-block{
                    display: inline-block;
                    min-height: 100px;
                    .second-block{
                       
                    }
                }
            }
            .project-tracker-wrapper{
                overflow-x: auto;
                .inner-width-block{
                    overflow: unset;
                }
                .row-header{
                    .item-block{
                        display: none;
                    }
                }
                .scrollbar-hidden{
                    overflow: unset !important;
                    .scrollbar-hidden{
                        overflow: unset !important;
                        height: auto !important;  
                    }
                }
            }
            .core-details-block{
                margin: 0;
                .core-wrapper{
                    overflow-x: auto;
                    .core-heading{
                        display: inline-block;
                        white-space: nowrap;
                        width: auto;
                        .detail-left-block{
                            display: inline-block;
                            white-space: nowrap;
                            width: auto;
                            .list-row{
                                display: inline-block;
                                white-space: nowrap;
                                width: auto;
                                >div{
                                    width: 250px;
                                    display: inline-block;
                                }
                            }
                        }
                        .outer-detail-right-block{
                            display: none;
                        }
                    }
                    .infinite-scroll-component{
                        overflow: unset !important;
                        height: auto !important;  
                        .db-height-block{
                            .list-data-row{
                                display: inline-block;
                                white-space: nowrap;
                                width: auto;
                                >div{
                                    width: 250px;
                                    display: inline-block;
                                    vertical-align: top;
                                    height: 100%;
                                }
                            }
                        }
                        .cd-detail-wrapper{
                            display: none;
                        }
                    }
                    .core-details{
                        width: auto;
                        display: inline-block;
                    }
                }
            }
        }
        .program-planner-block{
            margin-left: 40px;
            .project-heading {
                a{
                    padding: 8px 16px;
                }
            }
            .add-month-type, .planner-tracker, .project-tracker-block, .project-range-block{
                margin-right: 0 !important;
            }
            .table-list-filter{
                padding: 0 !important !important;
            }
            table{
                tr{
                    table-layout: auto;
                }
            }
            .simulation-month-sidebar{
                .item-detail-block{
                    height: 180px !important;
                }
                table{
                    tr{
                        table-layout: fixed;
                    }
                }
                .innerheading-block{
                    width: 100% !important;
                }
            }
        }
    }
}
