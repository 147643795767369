@import '../../../../assets/styles/setting';
  .transfer-modal{
    .modal-content{
      .modal-header{
        .modal-title{
          h2{
            font-size: 20px;
          }
          img{
            width: 20px;
          }
        }
      }
      .modal-body{
        .transfer-modal-body{
          width: 100%;
          margin-bottom: 30px;
          img{
            height: 20px;
            margin-top: -10px;
          }
          h2{
            font-size: 22px;
            color: #353A4A;
            margin-bottom: 30px;
          }
          .apps-container{
            margin-left: 0px;
            margin-right: 0px;
            width: 100%;
            h4{
              font-size: 16px;
              margin-bottom: 10px;
            }
            .apps-name{
              font-size: 13px;
              span{
                cursor: pointer;
              }
            }
            p{
              font-size: 12px;
              margin-top: 20px;
              border: 1px solid #353A4A;
              border-radius: 3px;
              padding: 10px;
              background: #DFE4E9;
              width: 100%;
            }
          }
        }
        .transfer-modal-footer{
          .list-inline{
            text-align: right;
            margin-left: 10px;
            .btn-secondary{
              cursor: pointer;
              font-family: Conv_IBMPlexSans-Regular;
              font-size: 12px;
              color: #634109;
              padding: 6px 25px;
              margin-right: 20px;
              border-radius: 3px;
              border: 1px solid #b47a1a;
              background: #f5a723;
              &:disabled{
                cursor: not-allowed;
                background: #ffe7bf;
              }
            }
            a{
              color: #353A4A;
            }
          }
        }
      }
    }
  }
  .edit-header-side-bar{
    position: fixed;
    background: #fff;
    z-index: 1000;
    // box-shadow:0 2px 4px 0 rgba(0,0,0,0.50);
    height: 100vh;
    top:0;
    transition:all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    width:470px;
    .lopa-photo-block{
      position: relative;
       .lopa-photo-hover{
          display: block;
          background: rgba(0,0,0,.5);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          opacity: 0;
          z-index: 9;
          transition: opacity 0.2s ease;
          b{
            font-size: 16px;
            font-family: Conv_IBMPlexSans-Regular;
            color: #fff;
            display: block;
            width: 100%;
            margin: 10px 0;
            cursor: pointer;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: inline-block;
            width: 150px;
            z-index: 9;
            img{
              display: inline-block;
              margin-right: 10px;
            }
            input[type="file"]{
              cursor: pointer;
              opacity: 0;
              width: 150px;
              position: absolute;
              left: 0;
              right: 0;

            }
          }

        }
      &:hover{
        .lopa-photo-hover{
          opacity: 1;

        }
      }
    }

    &.hide-edit{right:-470px}
    &.show-edit{right: 0}
    .blue-icon{margin-right:6px}
    .blue-action{
      font-size: 12px;
      color:$color_cff;
      font-family: $ibmplexRegular;
    }
    .ml-security-data{
      margin-bottom: 20px !important;
      padding: 0 0 10px;
      height: 150px;
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      background: #fafafa;
      margin: 0 25px;
      padding: 15px;
      h4{
        font-family: $ibmplexSemiBold;
        margin-bottom:6px;
      }
      .security-ml-data{
        border-bottom:1px solid #ddd;
        padding-bottom:10px;
        margin-bottom:10px;
        &:last-child{
          border-bottom:0;
        }
      }
    }
    h6{
      color:#9439e4;
      font-size: 14px!important;
      line-height:20px;
      margin: 30px 0 10px 25px;
      &.error-msg{
        font-size: 12px !important;
        color: red !important;
        padding: 3px !important;
        margin:0px !important;
      }
    }
    h3{
      margin: 28px 15px 28px 25px;
      position: relative;
      font-family:$ibmplexRegular;
      font-size:16px;
      color:$color_9e4;
      border-bottom:1px solid #e5e5e5;
      padding-bottom:11px;
      top:0;
      img{
        position: absolute;
        right: 6px;
        top: 7px;
        cursor: pointer;
      }
    }

    form{
      .warning-msg{
        background-color: #fffbd8;
        border: 1px solid #ffebd8;
        border-radius: 3px;
        margin: -12px 37px 7px;
        padding: 6px;
        width: 100%;
        font-size: 12px;
      }

      .form-group{
        margin-bottom:30px;
        padding: 0 15px 0 25px;
        // contract scss
         &.form-group-margin{
          margin-bottom:10px;
         }
         &.border-form{
          border-top:1px solid #9439e4;
          padding: 20px 0px 0px 0px;
          width: 94%;
          margin-left: 25px;
         }

        .checkbox-blk{
          margin-right:12px;
          cursor: pointer;
          input[type="checkbox"]{
            cursor: pointer;
            position: relative;
            top: -2px;
          }
        }
         .edit-table-block{
            color: #2F8CFF;
            font-size: 12px;
            display: block;
            text-align: right;
            margin-bottom: 5px;
            img{
              width:10px;
              margin-right: 5px;
            }
         }
         .file_attachment{
            cursor:pointer;
            color: #2F8CFF;
            position: relative;
            input[type="file"]{
              position: absolute;
              width: 256px;
              opacity: 0;
              left:0px;
              cursor:pointer;
            }
          }
          .img_attachment{
            width:25px;
            padding-right:5px;
            padding-bottom:5px;
          }
        .radio-button{
          label{
            margin-right:20px;
            input[type="radio"]{
              margin-right:4px;
            }
          }
        }

        label{
          display: inline-block;
          margin-bottom:0;
        }
        select,
        .Select{
          width:100%;
          border-bottom:1px solid #8e8e8e;
        }
        input[type="text"], textarea{
          border-bottom:1px solid #8e8e8e;
          width:100%;
          color:$color_000;
        }
        input[type="checkbox"]{
          margin-right:5px;
        }
        textarea{
          resize: none;
          height:35px;
        }
        p{
          font-family:$ibmplexRegular;
          font-size:14px;
          color:$color_000;
          line-height:20px;
        }
        h4{
          color:#2f8cff;
          font-size: 12px;
          text-align: center;

          cursor:pointer;

        }
      }
      .escaltion-seperator{
        margin-left:25px;
        color: $color_9e4;
        &.border-form{
          border-top:1px solid #9439e4;
          padding-top:20px;
          margin-right: 25px;
         }
      }
      .table-block{
        padding:0 25px;
        table{
          margin-bottom:8px;
          .form-group{margin-bottom: 20px}
          .Select{width: 160px !important}
          th{padding: 8px 12px}
          td{
            .form-group{
              padding: 2px 15px 15px;
              margin: 0;
            }
            h6{
              padding:10px;
              font-size: 14px !important;
              &.error-msg{
                font-size: 12px !important;
                margin:0px !important;
              }
            }
            &.delete-mr-ut{
              border:none;
              padding: 20px;
              cursor: pointer;
            }
          }
        }
        .add-mr-int{
          color: #2f8cff;
          display:  inline-block;
          margin-bottom: 15px;
          font-size: 12px;
          border-bottom:1px solid $color_fff;
          &:hover{
            border-bottom:1px solid #2f8cff;
          }
        }
      }

      .location-search-input{
        border-bottom: 1px solid #8e8e8e;
        width: 93%;
        color: #000;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #aaa;
       }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #aaa;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: #aaa;
      }
    }
    }
    .submit-block{
      padding:15px 28px 15px 28px;
      background:#f0f2f3;
      position: absolute;
      bottom: 0;
      width:100%;
      input[type="submit"]{
        cursor: pointer;
        font-family:$ibmplexRegular;
        font-size:12px;
        color:$color_fff;
        padding: 6px 25px;
        margin-right: 30px;
        &:hover{
          color:$color_fff;
        }
    }
    a{
      cursor: pointer;
      font-family:$ibmplexRegular;
      font-size:12px;
      color:$color_cff;
      &:hover{
        color:$color_cff;
      }
    }
  }
// tab scss
  .edit-working-nav{
    margin: 0 24px 30px;
    .nav-link{
      font-family:$ibmplexRegular;
      font-size:12px;
      color:#626262;
      text-align:center;
      cursor:pointer;
      padding: 0 10px 4px;
      margin-right: 12px;
      &.active{
        background: transparent;
        border-color:transparent;
        font-family:$ibmplexSemiBold;
        font-size:12px;
        color:#1c97fb;
        border-bottom:2px solid #1c97fb;
        &:hover{
          border-bottom:2px solid #1c97fb;
        }
      }
      &:hover{
        border-color:transparent;
        padding: 0 10px 4px;
      }
    }
  }
  .import-existing-block{
    .import-inner-block{
      .search-icon-block{
        border-bottom:1px solid #c6cddd;
        margin: 0 30px;
        input{
          width:90%;
        }
      }
      ul{
        margin:10px 0;
        height: 385px;
        overflow: auto;
        &::-webkit-scrollbar{
          display: none;
        }
        li{
          cursor: pointer;
          padding: 8px 30px 3px;
          border-radius:3px;
          &:hover{
            background:#f2f2f2;
          }
          label{
            display: block;
            width:100%;
            input[type="checkbox"]{
              margin-right:10px;
            }
            span{
              font-family:$ibmplexRegular;
              font-size:14px;
              color:#8b8d91;
              line-height:20px;
              &.mail-text{
                float:right;
                position: relative;
                top:2px;
              }
            }
          }
        }
      }
    }
  }
  .react-datepicker-wrapper{
    width:100%;
    .react-datepicker__input-container{
      width:100%;
    }

  }


  .data-log-sidebar{
    .data-log-view-block{
        margin-bottom: 20px;
        margin-left: 60px;
      h5{
        font-family: $ibmplexRegular;
        font-size: 16px;
        color: #9439e4;
        margin-bottom: 2px;

      }
      h6{
        font-family: $ibmplexRegular;
        font-size: 12px !important;
        color: #8c9ba5 !important;
      }
      p{
        font-family: $ibmplexRegular;
        font-size: 15px;
        color: #000;
      }
    }
  }
  .ai-assistant-instruction{
    display: block;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: -15px;
    .sidebar-form{
      >p{
        font-family: $ibmplexRegular;
        font-size: 14px;
        color: #000000;
        line-height: 18px;
        margin-bottom: 30px;
      }
      ul{
        li{
          position: relative;
          padding-left: 65px;
          border-bottom: 1px solid #dddddd;
          margin-bottom: 25px;
          padding-bottom: 20px;
          padding-top: 0;
          &:last-child{
            border-bottom: none;
          }
          img{
            position: absolute;
            left: 0;
            top: 4px;
          }
          h2{
            font-family: $ibmplexSemiBold;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 2px;
          }
          p{
            font-family: $ibmplexRegular;
            font-size: 14px;
            line-height: 18px;
            color: #a0a0a0;
            margin-bottom: 5px;
          }
          a{
            font-family: $ibmplexRegular;
            font-size: 14px;
            color: #2f8cff;
          }
        }
      }
    }
  }
  .reviewer-issue-drawer{
    .resolved-list{
      padding: 0 25px;
      margin-top: -10px;
      li{
        border-bottom: 1px solid #eeeeee;
        padding: 10px 0 10px 0;
        position:relative;
        .issued-date,.edited-on{
          font-family: $ibmplexRegular;
          font-size: 14px;
          color: $color_000;
          display: block;
          img{
            height: 23px;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        .edited-on{
          color: #929498;
          font-size: 12px;
        }
        .user-heading{
          font-family: $ibmplexRegular;
          font-size: 12px;
          color: #f0a4ff;
          margin-bottom: 5px;
        }
        .user-details{
          position :relative;
          padding-left: 30px;
          margin-bottom: 15px;
        }
        &:last-child{
          border-bottom: none;
        }
      }
    }
  }
}
