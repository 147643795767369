@import '../setting';
body{
    padding-left: 0;
    .edit-licence-container{
      h4{
        font-size: 12px;
        line-height: normal;
        font-weight: 400;
        color: #8f8f8f;
        font-family: Conv_IBMPlexSans-Regular;
        margin-bottom: 5px;
      }
      .list-inline{
        text-align: right;
        margin: 10px;
        a{
          font-size: 14px;
          color: #2f8cff;
          &:hover{
            color: #2f8cff;
          }
        }
        button{
          font-size: 14px;
        }
      }
      .caret-list{
          display: inline-block;
          position: relative;
          left: 8px;
          top: 4px;
          i{
              display: block;
              color: #2893ff;
              line-height: 8px;
              cursor: pointer;
              font-size: 14px;
              overflow: hidden;
          }
      }
    }
    .edit-card-modal{
      .card-list-item{
        cursor: pointer;
        margin-top: 10px;
        margin-left: 0px;
        margin-right: 0px;
        padding: 5px 10px;
        border: 1px solid #d3d3d3;
        border-radius: 5px;
        .card-number{
          text-transform: capitalize;
          span{
            font-size: 14px;
            span{
              display: block;
              font-size: 12px;
              margin-top: -5px;
            }
          }
        }
        .expiry-date{
          padding-top: 10px;
          font-size: 12px;
        }
        .col-md-2{
          padding-right: 0px;
          padding-left: 0px;
        }
        .col-md-1{
          padding-right: 0px;
          padding-left: 0px;
        }
        .col-md-6{
          padding-left: 0px;
        }
        img{
          width: 40px;
          margin-top: 5px;
          border: 1px solid #d3d3d3;
          border-radius: 5px;
        }
      }
      .payment-list-item-row{
        .card-number{
          text-transform: capitalize;
          span{
            font-size: 14px;
            span{
              display: inline-block;
              font-size: 11px;
              margin-top: 0px;
              color: #b0b0b0;
            }
          }
          .expiry-date{
            font-size: 14px;
            display: block;
            font-size: 11px;
            margin-top: 0px;
            color: #b0b0b0;
            padding-top: 0px;
          }
          .non-default-card{
            float: right;
            border: 1px solid #b0b0b0;
            border-radius: 3px;
            padding: 0px 10px;
          }
          .default-card{
            position: absolute;
            right: 25px;
            border: 1px solid #3c86fa;
            border-radius: 3px;
            padding: 0px 10px;
            color: #fff;
            background: #3c86fa;
          }
          .edit-menu{
            position: absolute;
            left: 95%;
            top: -20%;
            .dropdown, .show{
              display: inline;
              left: 44%;
              button{
                background: transparent;
                border: none;
                padding: 0px;
                &:focus{
                  box-shadow: none;
                }
              }
              img{
                border: none;
                border-radius: 0px;
                height: 12px;
                width: 12px;
              }
              .dropdown-menu{
                min-width: 80px;
                .dropdown-item{
                  padding-left: 10px;
                  padding-right: 10px;
                  display: block;
                  margin-top: 0;
                  color: #b0b0b0;
                  padding-top: 0;
                  h4{
                    font-size: 11px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .onboarding-outer-wrapper{
        background-image: url('../../img/auth_banner.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        padding: 0px 0 0px 0;
        min-height: calc(100vh - 0px);
        position: relative;
        margin-left: -24px;
        margin-right: -24px;
        &.pricing-wrapper{
            background-image: url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/phase-2/background.png');
            background-position: 50%;
            background-repeat: no-repeat;
            width: 100%;
            display: block;
            padding: 25px 0;
            height: 300px;
        }
        .navbar-row{
            margin-bottom: 30px;
        }
        .logo{
            width: 60px;
        }
        .sign-in-btn{
            @include elementStyles(14px, #ffffff, normal, normal, $ibmplexRegular, null, null);
            a{
                display: inline-block;
                margin-left: 15px;
                &:hover,&:active,&:focus,&:visited{
                   color: #ffffff;
                }
            }
        }
        .hero-text-wrapper{
            h1{
                @include elementStyles(36px, #ffffff, normal, normal, $ibmplexSemiBold, null, null);
                margin-bottom: 20px;
            }
            h3{
                @include elementStyles(20px, #ffffff, normal, normal, $ibmplexRegular, null, null);
                margin-bottom: 20px;
            }
            h2{
                @include elementStyles(24px, #ffffff, normal, normal, $ibmplexRegular, null, null);
                opacity: 0.63;
            }
        }
        .signup-form,.login-form,.email-verified{
            .Select-placeholder{
              padding-left: 0px;
            }
            .nav-tabs{
                border: none;
                display: block;
                margin-bottom: 15px;
                li{
                    margin-bottom: 10px;
                    display: inline-block;
                    label{
                       border: none;
                       display: block;
                       position: relative;
                       padding:0;
                       @include elementStyles(13px, #000000, 1.43, normal, $ibmplexMedium, null, null);
                       input{
                        margin-right: 8px;
                        position: relative;
                        bottom: 1px;

                       }
                       a{
                            @include elementStyles(12px, #3f51b5, 1.67, normal, $ibmplexRegular, null, null);
                            text-decoration: underline;
                            display: inline-block;
                            margin-left: 10px;
                       }
                    }
                }
            }
        }
        .main-wrapper{
          background: #FFFFFF;
          box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 20px;
          position: relative;
          .sparta-logo{
            position: relative;
            left: 15px;
            top:15px;
            width: 50px;
          }
        }
        .solution-link {
          @include elementStyles( 14px, #ffffff!important, normal, normal, $ibmplexSemiBold, null, null);
          float: right;
          background-color: #3f51b5;
          padding: 8px 10px;
          border-radius: 4px;
          margin: 15px 10px;
          text-transform: uppercase;
        }
        .form-wrapper{
          padding: 60px 50px 60px;
            .form-title{
                @include elementStyles(24px, #000000, normal, normal, $ibmplexMedium, null, null);
                margin-bottom: 15px;
            }
            label{
                span{
                    @include elementStyles(11px, null, normal, normal, $ibmplexRegular, null, null);
                }
            }
            input[type="text"],input[type="password"]{
                height: auto;
                font-size: 16px;
            }
            input[type="submit"]{
                background: #3f51b5;
                border-color: #3f51b5;
                color: #fff;
                width: 100%;
                padding: 7px;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                font-size: 14px;
                text-transform: uppercase;
                margin-top: 15px;
                box-sizing: border-box;
                transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                font-weight: 500;
                line-height: 1.75;
                border-radius: 4px;
                letter-spacing: 0.02857em;
            }
            .error-msg{
                position: relative;
                bottom: 15px;
            }
            .login-form{
                .form-title{
                    margin-bottom: 10px;
                }
                .password-grid{
                    i{
                        top: 23px;
                    }
                }
                .email-textfield{
                  margin-bottom: 30px;
                }
            }
            .form-subtitle{
                @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                margin-bottom: 30px;
            }
            .forgot-password{
                @include elementStyles(13px, #0093ff, 1.67, normal, $ibmplexRegular, null, null);
                float: right;
                position: relative;
                bottom: 8px;
                margin-bottom: 5px;
            }
            .back-to-login{
                @include elementStyles(14px, #3f51b5, 1.67, normal, $ibmplexRegular, null, null);
                display: inline-block;
                margin-bottom: 10px;
                .MuiSvgIcon-root{
                  position: relative;
                  top: 8px;
                  left: -3px;
                }
            }
            .terms-and-conditions{
                @include elementStyles(13px, #010101, 20px, normal, $ibmplexRegular, null, null);
                margin: 20px 0 10px 0;
                text-align: center;
                clear: both;
                a{
                    @include elementStyles(13px, #0093ff, 20px, normal, $ibmplexRegular, null, null);
                }
            }
            .Select-control{
                border: none;
                border-bottom: solid 1px #979797;
                border-radius: 0;
                margin-bottom: 15px;
                .Select-arrow{
                    left: 8px;
                }
            }
            .tech-sum-item{
                margin-bottom: 0;
            }
            .password-grid{
                i{
                    position: absolute;
                    right: 25px;
                    top: 35px;
                    cursor: pointer;
                }
                input{
                    padding-right:25px;
                }
            }
            .MuiInputAdornment-root{
              .MuiSvgIcon-root{
                font-size: 18px;
                opacity: 0.7;
              }
            }
            .not-registered{
              @include elementStyles(12px, #000000, normal, normal, $ibmplexRegular, null, null);
              margin-top: 10px;
              letter-spacing: 0.3px;
              a{
                text-decoration: underline;
              }
            }
        }
        .img-slider-wrapper{
          background: #C3EAFF;
          border-radius: 0px 20px 20px 0px;
          box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
          padding: 30px 30px 0 30px;
          height: 540px;
          .application-info-card{
            img{
              margin: 0 auto;
              width: 280px;
              margin-bottom: 40px;
              &.fin-s, &.commverge{
                width: 240px;
              }
            }
            h4{
              @include elementStyles($size: 24px, $fontFamily: $ibmplexMedium, $color: #000000, $margin: 0 0 15px 0);
            }
            p{
              @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #000000, $margin: 0 0 15px 0);
              line-height: 26px;
              word-spacing: 2px;
              letter-spacing: 1px;
            }
          }
          .image-slider{
            .slick-dots{
              li{
                button{
                  &::before{
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
        .email-verified{
            .loader-gif{
                width: 100px;
            }
            .form-title{
                margin-bottom: 10px;
            }
            img{
                margin: 10px  0;
            }
            .ok-btn{
                @extend %primary-botton;
                padding: 15px;
                @include elementStyles(14px, #ffffff, normal, normal, $ibmplexRegular, null, null);
                letter-spacing: 0.7px;
                margin-top: 25px;
                display: inline-block;
                width: 150px;
                margin-bottom: 10px;
            }
            .paragraph{
                @include elementStyles(16px, #4f6276, normal, normal, $ibmplexRegular, null, null);
            }
        }
    }
    .password-tooltip{
        margin-top: 7px;
        li{
            @include elementStyles(11px, #ffffff, normal, normal, $ibmplexRegular, null, null);
            position: relative;
            margin-bottom: 5px;
            padding-left: 10px;
            &:before{
                content: '*';
                position: absolute;
                left : 0;
                top: 1px;
            }
        }
    }
    .features-wrapper{
        padding: 70px 0 40px;
        margin-left: -15px;
        margin-right: -15px;
        background-color: #ffffff;
        .heading-block{
            text-align: center;
            h2{
                @include elementStyles(24px, #7232d4, normal, normal, $ibmplexRegular, null, null);
            }
            h3{
                @include elementStyles(24px, #000000, normal, normal, $ibmplexRegular, null, null);
                margin-top: 5px;
                margin-bottom: 10px;
            }
        }
        .card-block{
          position: relative;
            margin-bottom:20px;
            background: #ffffff;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
            border-radius: 3px;
            max-width:320px;
            margin-top: 20px;
            margin-left: 15px;
            padding: 0px;
            &:first-child{
                margin-left: 0px;
            }
            h4{
              display: block;
              padding:10px;
              height: 93px;
              border-radius: 3px 3px 0px 0px;;
              background-image: linear-gradient(to right, #6538db, #0a56a8);
              background-image: -webkit-linear-gradient(to right, #6538db, #0a56a8);
              background-image: -moz-linear-gradient(to right, #6538db, #0a56a8);
              color: #ffffff;
              text-align: center;
              padding-top: 25px;
            }
            &:hover{
                box-shadow: 0 8px 16px 0 rgba(0,0,0,0.15);
            }
            .sign-button{
              min-width: 200px;
              display: inline-block;
              border: 1px solid #2893ff;
              padding: 10px;
              &:hover{
                background: #2893ff;
              }
            }
          }
        .feature-content-block{

            .list-inline{
                text-align: center;
                margin-bottom: 80px;
                li{
                    display: inline-block;
                    a{
                        background-color: #f7f7f7;
                        @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                        padding: 10px 40px;
                        display: inline-block;
                        width: 235px;
                        border: 1px solid #c2c2c2;
                        &.tab-active{
                            color: #ffffff;
                            border: 1px solid #0a56a8;
                            background-image: linear-gradient(to right, #6538db, #0a56a8);
                            background-image: -webkit-linear-gradient(to right, #6538db, #0a56a8);
                            background-image: -moz-linear-gradient(to right, #6538db, #0a56a8);
                        }
                    }
                }
            }
            .tab-content{
                .background-difference{
                    padding:40px;
                    margin: 0px 0 30px;
                    background: #FBF9FE;
                    &.last-background{
                        margin: 0px;
                    }
                  .download-links{
                    background: #F4F0FA;
                    border-radius: 10px;
                    margin-top: 15px;
                    padding: 15px;
                  }
                }
                img{
                    max-width: 100%;
                }
                .flex-centered{
                    padding-bottom: 40px;
                }
                h4{
                    @include elementStyles(24px, #000000, normal, normal, $ibmplexRegular, null, null);
                    margin-bottom: 10px;
                }
                p{
                    @include elementStyles(16px, #4f6276, normal, normal, $ibmplexRegular, null, null);
                  &.card{
                    @include elementStyles(12px, #777777, normal, normal, $ibmplexRegular, null, null);
                    border: none;

                  }

                }
                ul{
                  &.list-unstyled{
                    padding-left: 35px;
                  }
                    margin-top: 30px;
                    text-align: left;
                    margin-bottom: 100px;
                    li{
                        @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                        margin-bottom: 15px;
                        position: relative;
                        padding-left: 15px;
                        &::before{
                            content: '';
                            position: absolute;
                            width: 6px;
                            height: 6px;
                            background-color: #3f51b5;
                            border-radius: 50%;
                            left: 0;
                            top: 6px;
                        }
                    }
                }
                .wrapper{
                  position: absolute;
                  width: 100%;
                  left: 50%;
                  transform: translateX(-50%);
                  bottom: 15px;
                }
                .signup{
                  bottom: 30px;
                }
                .contact{
                  bottom: 30px;
                }
            }
        }
        &.on-boarding{
            position:fixed;
        }
    }
    footer{
        background-color: #191c26;
        margin-left: -15px;
        margin-right: -15px;
        padding: 70px 0 10px;
        .container{
            >.row{
                margin-bottom: 30px;
            }
        }
        h3{
            @include elementStyles(16px, #ffffff, normal, normal, $ibmplexRegular, null, null);
            margin-bottom: 20px;
        }
        ul{
            li{
                margin-bottom: 15px;
                a{
                    @include elementStyles(13px, #bec0c7, normal, normal, $ibmplexRegular, null, null);
                    &:hover,&:active,&:focus{
                        color: #ffffff;
                    }
                }
            }
            &.images-list{
                li{
                    display: inline-block;
                    margin-right: 15px;
                    a{
                        display: inline-block;
                        border-radius: 50%;
                        border: solid 1px rgba(78, 200, 244, 0.16);
                        height: 30px;
                        width: 30px;
                        position: relative;
                        img{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            -webkit-transform: translate(-50%, -50%);
                            -moz-transform: translate(-50%, -50%);
                            -ms-transform: translate(-50%, -50%);
                            -o-transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }
        .copy-right{
            @include elementStyles(13px, #bec0c7, normal, normal, $ibmplexRegular, null, null);
            img{
                width: 100px;
            }
            p{
                margin-top: 10px;
                a{
                    @include elementStyles(13px, #bec0c7, normal, normal, $ibmplexRegular, null, null);
                }
            }
        }
    }
    .modal {
        text-align: center;
        padding: 0!important;
        z-index: 9999;
    }
    .modal:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
    }
    .modal-dialog {
        display: inline-block;
        min-width: 600px;
        text-align: left;
        vertical-align: middle;
    }
    //home page scss
    #infoModal{
        max-width: 800px;
        .modal-header{
            border: 1px solid #0a56a8;
            background-image: linear-gradient(to right, #6538db, #0a56a8);
            background-image: -webkit-linear-gradient(to right, #6538db, #0a56a8);
            background-image: -moz-linear-gradient(to right, #6538db, #0a56a8);
            padding: 30px;
            position :relative;
            .modal-title{
                @include elementStyles(24px, #ffffff, 1.5, normal, $ibmplexMedium, null, null);
            }
            .close{
                position: absolute;
                right: 10px;
                top: 3px;
                transition: all 0.4s ease;
                -webkit-transition: all 0.4s ease;
                -moz-transition: all 0.4s ease;
                -ms-transition: all 0.4s ease;
                -o-transition: all 0.4s ease;
                span{
                    color: #ffffff;
                }
                &:hover{
                    opacity: 1;
                    transition: all 0.4s ease;
                    -webkit-transition: all 0.4s ease;
                    -moz-transition: all 0.4s ease;
                    -ms-transition: all 0.4s ease;
                    -o-transition: all 0.4s ease;
                    span{
                        color: #ffffff;
                        opacity: 1;
                    }
                }
            }
        }
        .modal-body{
            padding: 30px;
            .modal-content{
                @include elementStyles(16px, #000000, 2, normal, $ibmplexRegular, null, null);
                border: none;
            }
        }
    }
    .license-users{
        background-color: #ffffff;
        margin-right: -15px;
        min-height: 100vh;
        .license-sidebar{
            position: fixed;
            top : 41px;
            left: 70px;
            background: rgba(113, 99, 186, 0.9);
            bottom: 0;
            overflow: auto;
            &::-webkit-scrollbar{
              display: none;
            }
            padding: 0;
            width:150px;
            h2{
                @include elementStyles(16px, #f0a4ff, normal, normal, $ibmplexRegular, null, null);
                padding: 10px 15px;
            }
            // ul{
            //     li{
            //         a{
            //             @include elementStyles(12px, #ffffff, normal, normal, $ibmplexRegular, null, null);
            //             padding: 10px 15px;
            //             display: block;
            //             &.active{
            //                 background: -moz-linear-gradient(left,hsla(0,0%,100%,.5) 0,hsla(0,0%,100%,0) 100%);
            //                 background: -webkit-linear-gradient(left,hsla(0,0%,100%,.5),hsla(0,0%,100%,0));
            //                 background: linear-gradient(90deg,hsla(0,0%,100%,.5) 0,hsla(0,0%,100%,0));
            //                 filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#00ffffff",GradientType=1);
            //                 color: #fff;
            //             }
            //         }
            //     }
            // }
            ul{
                li{
                  margin: 0px 5px;
                  a{
                    display:block;
                    padding-left: 32px;
                    cursor: pointer;
                    font-family: $ibmplexRegular;
                    font-size:12px;
                    color:$color_fff !important;
                    padding: 10px 15px;
                    &:hover{
                      color:$color_fff !important;
                    }
                    &.active{
                      background: #fafafa;
                      color: #000!important;
                      border-radius: 4px;
                        &:hover{
                          color: #000;
                        }
                    }
                  }
                }
              }
        }
        .license-content-wrapper{
            padding: 20px 15px 15px;
            padding-left: 220px;
            .block-title{
                @include elementStyles(16px, #bd10e0, normal, normal, $ibmplexRegular, null, null);
                margin-bottom: 5px;
            }
            .block-title-tag{
                @include elementStyles(13px, #838383, normal, normal, $ibmplexRegular, null, null);
                margin-bottom: 15px;
            }
            .users-license{
                margin-bottom: 35px;
                .pricing-outer{
                    border: 1px solid #dddddd;
                    box-shadow: 0 2px 4px 0 rgba(233, 233, 233, 0.5);
                    border-radius: 6px;
                    margin: 0;
                    padding: 20px 5px;
                    width: 100%;
                    margin-bottom: 15px;
                    .pricing-grid{
                        border-right: 1px solid #dddddd;
                        padding-left: 0;
                    }
                    .switcher{
                        li{
                            @include elementStyles(12px, #000000, normal, normal, $ibmplexRegular, null, null);
                            .switch{
                                .slider{
                                    background-color: #ffffff;
                                    border: 1px solid  #2893ff;
                                    height: 26px;
                                    width: 52px;
                                    position: relative;
                                    top: -7px;
                                    &::before{
                                        background-color: #2893ff;
                                        height: 18px;
                                        width: 18px;
                                        left: 3px;
                                        bottom: 3px;
                                    }
                                }
                            }
                            &:last-child{
                                margin-left: 12px;
                            }
                        }
                    }
                    .price{
                        @include elementStyles(40px, #000000, normal, normal, $ibmplexRegular, null, null);
                        margin-top: 25px;
                        span{
                            @include elementStyles(12px, #898989, normal, normal, $ibmplexRegular, null, null);
                            margin: 5px 0;
                            display: block;
                              &:first-child{
                                color: #000;
                                display: inline-block;
                                font-size: 35px;
                              }
                        }
                    }
                    .users-grid{
                        padding-left: 25px;
                        .pricing-calculations{
                            li{
                                @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                                &:first-child{
                                    @include elementStyles(14px, #000000, normal, normal, $ibmplexMedium, null, null);
                                }
                            }
                            .caret-list{
                                position: relative;
                                top: 4px;
                                i{
                                    display: block;
                                    color: #2893ff;
                                    line-height: 10px;
                                    cursor: pointer;
                                }
                            }
                        }
                        .total-price{
                            margin: 15px 0;
                            vertical-align: middle;
                            li{
                                vertical-align: top;
                                &:first-child{
                                    @include elementStyles(20px, #62b305, normal, normal, $ibmplexRegular, null, null);
                                }
                                &:last-child{
                                    @include elementStyles(12px, #9d9d9d, normal, normal, $ibmplexRegular, null, null);
                                }
                            }
                        }
                        .buy-license{
                            @extend %primary-botton;
                            padding: 8px;
                            @include elementStyles(14px, #ffffff, normal, normal, $ibmplexRegular, null, null);
                            display: inline-block;
                            min-width: 85px;
                            text-align: center;
                        }
                    }
                }
                .contact-sales{
                    border: 1px solid #dddddd;
                    box-shadow: 0 2px 4px 0 rgba(233, 233, 233, 0.5);
                    border-radius: 6px;
                    margin: 0;
                    padding: 20px 0;
                    align-items: center;
                    margin-bottom: 15px;
                    img{
                        position: absolute;
                        left: 15px;;
                        top: 50%;
                        transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        width: 50px;
                    }
                    .sales-content{
                        padding-left: 70px;
                        p{
                            @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                            margin-bottom: 1px;
                        }
                        a{
                            @include elementStyles(14px, #2893ff, normal, normal, $ibmplexRegular, null, null);
                        }
                    }
                }
                &.mr-calculator{
                    .mr-pricing-grid{
                        padding-right: 0;
                        display: flex;
                        display: -webkit-flex;
                        flex-flow: wrap;
                        -webkit-flex-flow: wrap;
                        &:before,&:after{
                            width: 0;
                        }
                        .pricing-grid{
                            >div{
                                width: 100%;
                            }
                            .title{
                                position: relative;
                                vertical-align: middle;
                                @include elementStyles(18px, #000000, 18px, normal, $ibmplexRegular, null, null);
                                img{
                                    width: 30px;
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-right: 5px;
                                }
                                >div{
                                    display: inline-block;
                                    vertical-align: middle;
                                    text-align: left;
                                    span{
                                        @include elementStyles(12px, #898989, normal, normal, $ibmplexRegular, null, null);
                                        margin-top: 5px;
                                        display: block;
                                        text-align: left;
                                    }
                                }
                                .pricing-desctiption{
                                  text-align: left;
                                  display: block;
                                  font-size: 12px;
                                  line-height: normal;
                                  font-weight: 400;
                                  color: rgb(157, 157, 157);
                                  font-family: Conv_IBMPlexSans-Regular;
                                  position: relative;
                                  left: 42%;
                                  top: 0px;
                                  width: 65%;
                                }
                            }
                            .price{
                                margin-top: 15px;
                                font-size: 36px;
                                span{
                                  &:first-child{
                                    color: #000;
                                    display: inline-block;
                                    font-size: 35px;
                                  }
                                }
                            }
                        }
                        .flex-end{
                            align-items: flex-end;
                        }
                    }

                }
            }
            .current-plans{
                .current-plans-row{
                    margin-right: -30px;
                    .wrapper{
                        border: 1px solid #dddddd;
                        box-shadow: 0 2px 4px 0 hsla(0,0%,91%,.5);
                        border-radius: 6px;
                        padding: 15px;
                        margin-bottom: 15px;
                        >.row{
                            align-items: center;
                            &:last-child{
                              margin-top: 10px;
                            }
                            >div{
                                padding-right: 0;
                            }
                            .dropdown, .show{
                              button{
                                background: transparent;
                                border: none;
                                padding: 0px;
                                &:focus{
                                  box-shadow: none;
                                }
                              }
                              .dropdown-menu{
                                min-width: 80px;
                                .dropdown-item{
                                  padding-left: 10px;
                                }
                              }
                            }
                        }
                        h4{
                            @include elementStyles(12px, #8f8f8f, normal, normal, $ibmplexRegular, null, null);
                            margin-bottom: 5px;
                        }
                        p{
                            @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                            .success-class{
                                color: #57a300;
                            }
                            .error{
                                color: #a30000;
                            }
                        }
                        .edit-icon{
                            height: 15px;
                            margin: 0 3px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .refer-fried-wrapper{
                background-color: #fffcd0;
                padding: 15px 5px 0;
                border-radius: 4px;
                margin-top: 55px;
                .refer-content-wrapper{
                    position: relative;
                    padding-left: 65px;
                    img{
                        position: absolute;
                        left: 5px;
                        top: 1px;
                        width: 50px;
                    }
                    .refer-friend-inner{
                        h3{
                            @include elementStyles(16px, #000000, normal, normal, $ibmplexMedium, null, null);
                        }
                        p{
                            @include elementStyles(11px, #403c00, 15px, normal, $ibmplexRegular, null, null);
                            margin: 11px 0 12px;
                            a{
                                color: #2893ff;
                            }
                        }
                        .code-block{
                            display: flex;
                            display: -webkit-flex;
                            flex-flow: wrap;
                            -webkit-flex-flow: wrap;
                            align-items: center;
                            margin-bottom: 15px;
                            &:before,&:after{
                                width: 0;
                            }
                            @include elementStyles(12px, #403c00, 14px, normal, $ibmplexRegular, null, null);
                            span{
                                display: inline-block;
                                margin-left: 15px;
                                background-color: #fff893;
                                border: 1px dashed #7d7401;
                                padding: 8px 30px;
                                border-radius: 4px;
                            }
                        }

                    }
                }
                .refer-links{
                    border-top: 1px solid #c9c48d;
                    margin: 0;
                    text-align: center;
                    padding: 5px 0;
                    a{
                        @include elementStyles(12px, #2893ff, normal, normal, $ibmplexRegular, null, null);
                        display: block;
                        padding: 5px 0;
                    }
                    >div{
                        border-right: 1px solid #c9c48d;
                        padding: 5px;
                        &:last-child{
                            border-right: none;
                        }
                    }
                }
            }
            .license-detail{
                background-color: #dfe4e9;
                padding: 15px;
                border-radius: 4px;
                margin-top: 15px;
                padding-bottom: 35px;
                position: relative;
                transition: height 0.25s ease-out;
                @include elementStyles(12px, #4e4e4e, normal, normal, $ibmplexRegular, null, null);
                h3{
                    @include elementStyles(16px, #000000, normal, normal, $ibmplexMedium, null, null);
                    margin-bottom: 30px;
                    img{
                        width: 24px;
                        margin-right: 10px;
                        position: relative;
                        bottom: 2px;
                    }
                }
                p,ul li{
                    @include elementStyles(12px, #4e4e4e, normal, normal, $ibmplexRegular, null, null);
                }
                &.license-height-auto{
                    height: auto !important;
                    transition: height 0.25s ease-in;
                }
                .show-btn{
                    position: absolute;
                    @include elementStyles(12px, #2893ff, normal, normal, $ibmplexRegular, null, null);
                    left: 15px;
                    bottom: 10px;
                }
            }
        }
        .readmore-sidebar,.referral-history{
            .application-edit-side-bar{
                #sidebar-header{
                    padding: 15px 0;
                    margin: 0 15px;
                    h3{
                        @include elementStyles(18px, #9439e4, normal, normal, $ibmplexRegular, null, null);
                    }
                    .close-sidebar{
                        right: 0;
                    }
                }
                .body-content{
                    padding: 15px;
                    p{
                        @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                        margin-bottom: 20px;
                    }
                    ul{
                        li{
                            @include elementStyles(12px, #000000, normal, normal, $ibmplexRegular, null, null);
                            padding-bottom: 15px;
                            border-bottom: 1px solid #efefef;
                            margin-left: 25px;
                            margin-bottom: 15px;
                            list-style-type: none;
                            position: relative;
                            &:before{
                                content: '';
                                position: absolute;
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                background-color: #9439e4;
                                left: -25px;
                                top: 3px;
                            }
                            &.inner-child{
                                &:before{
                                    content: '';
                                    position: absolute;
                                    width: 6px;
                                    height: 6px;
                                    border-radius: 50%;
                                    background-color: #9439e4;
                                    left: -25px;
                                    top:3px;
                                }
                            }
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
        .referral-history{
            .outer-wrapper{
                .first-block{
                    .height-block{
                        .vl{
                            border: 1px solid #308efd;
                        }
                    }
                }
                &:last-child{
                  .first-block{
                    .height-block{
                        .vl{
                            border: none;
                        }
                    }
                  }
                }
                .scnd-block{
                    h2{
                        @include elementStyles(16px, #000000, normal, normal, $ibmplexRegular, null, null);
                        margin-top: 2px;
                    }
                    span{
                        @include elementStyles(12px, #a4a4a4, normal, normal, $ibmplexRegular, null, null);
                        margin-bottom: 5px;
                    }
                    p{
                        @include elementStyles(12px, #404040, normal, normal, $ibmplexRegular, null, null);
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .billing-container, .cart-container{
        margin: 0 -15px;
        background: #ffffff;
        padding: 0 15px;
        .billing-sidebar{
            position: fixed;
            top : 41px;
            left: 70px;
            background-image: linear-gradient(-180deg,#6538db,#0a56a8);
            bottom: 0;
            overflow: auto;
            &::-webkit-scrollbar{
              display: none;
            }
            padding: 0;
            width:150px;
            h2{
                @include elementStyles(16px, #f0a4ff, normal, normal, $ibmplexRegular, null, null);
                padding: 10px 15px;
            }
            ul{
                li{
                    a{
                        @include elementStyles(12px, #ffffff, normal, normal, $ibmplexRegular, null, null);
                        padding: 10px 15px;
                        display: block;
                        &.active{
                            background: -moz-linear-gradient(left,hsla(0,0%,100%,.5) 0,hsla(0,0%,100%,0) 100%);
                            background: -webkit-linear-gradient(left,hsla(0,0%,100%,.5),hsla(0,0%,100%,0));
                            background: linear-gradient(90deg,hsla(0,0%,100%,.5) 0,hsla(0,0%,100%,0));
                            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#00ffffff",GradientType=1);
                            color: #fff;
                        }
                    }
                }
            }
        }
        .billing-content-wrapper{
            padding: 15px 0;
            padding-left: 220px;
            min-height: 95vh;
            .page-title{
                @include elementStyles(16px, #bd10e0, normal, normal, $ibmplexRegular, null, null);
                margin-bottom: 3px;
            }
            .block-subs{
                @include elementStyles(13px, #838383, normal, normal, $ibmplexRegular, null, null);
                margin-bottom: 15px;
            }
            .cardsList{
              font-size: 14px;
              .inner-con{
                margin-bottom: 20px;
                &::-webkit-scrollbar{
                  display: none;
                }
                .card-list-item{
                  cursor: pointer;
                  margin-top: 10px;
                  margin-left: 0px;
                  margin-right: 0px;
                  padding: 5px 10px;
                  border: 1px solid #d3d3d3;
                  border-radius: 5px;
                  .card-number{
                    text-transform: capitalize;
                    span{
                      font-size: 14px;
                      span{
                        display: block;
                        font-size: 12px;
                        margin-top: -5px;
                      }
                    }
                  }
                  .expiry-date{
                    padding-top: 10px;
                    font-size: 12px;
                  }
                  .col-md-2{
                    padding-right: 0px;
                    padding-left: 0px;
                  }
                  .col-md-1{
                    padding-right: 0px;
                    padding-left: 0px;
                  }
                  .col-md-6{
                    padding-left: 0px;
                  }
                  img{
                    width: 40px;
                    margin-top: 5px;
                    border: 1px solid #d3d3d3;
                    border-radius: 5px;
                  }
                }
                .payment-list-item-row{
                  .card-number{
                    text-transform: capitalize;
                    span{
                      font-size: 14px;
                      span{
                        display: inline-block;
                        font-size: 11px;
                        margin-top: 0px;
                        color: #b0b0b0;
                      }
                    }
                    .expiry-date{
                      font-size: 14px;
                      display: block;
                      font-size: 11px;
                      margin-top: 0px;
                      color: #b0b0b0;
                      padding-top: 0px;
                    }
                    .non-default-card{
                      float: right;
                      border: 1px solid #b0b0b0;
                      border-radius: 3px;
                      padding: 0px 10px;
                    }
                    .default-card{
                      position: absolute;
                      right: 25px;
                      border: 1px solid #3c86fa;
                      border-radius: 3px;
                      padding: 0px 10px;
                      color: #fff;
                      background: #3c86fa;
                    }
                    .edit-menu{
                      position: absolute;
                      left: 95%;
                      top: -20%;
                      .dropdown, .show{
                        display: inline;
                        left: 44%;
                        button{
                          background: transparent;
                          border: none;
                          padding: 0px;
                          &:focus{
                            box-shadow: none;
                          }
                        }
                        img{
                          border: none;
                          border-radius: 0px;
                          height: 12px;
                          width: 12px;
                        }
                        .dropdown-menu{
                          min-width: 80px;
                          .dropdown-item{
                            padding-left: 10px;
                            padding-right: 10px;
                            display: block;
                            margin-top: 0;
                            color: #b0b0b0;
                            padding-top: 0;
                            h4{
                              font-size: 11px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .active{
                  border: 1px solid #007bff;
                  font-weight: bold;
                  border-radius: 5px;
                  .fa-check-circle{
                    display: block;
                    font-size: 15px;
                    color: #007bff;
                    padding-top: 10px;
                    float: right;
                  }
                  .fa-pencil-square-o{
                    display: block;
                    font-size: 15px;
                    color: #007bff;
                    padding-top: 10px;
                    float: right;
                  }
                }
              }
              .btn-primary{
                width: 100%;
              }
            }
            .invoice-description{
                margin-bottom: 30px;
                .back-to-invoices{
                    @include elementStyles(12px, #2893ff, normal, normal, $ibmplexMedium, null, null);
                    display: inline-block;
                    margin-bottom: 15px;
                }
                .from-address{
                    @include elementStyles(12px, #000000, normal, normal, $ibmplexRegular, null, null);
                    span{
                        @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                        margin-bottom: 15px;
                        display: block;
                    }
                }
                .list-inline{
                    margin-top: 15px;
                    @include elementStyles(12px, #000000, normal, normal, $ibmplexRegular, null, null);
                    li{
                        margin-right: 15px;
                    }
                }
                .export-grid{
                    .donwload-invoice{
                        @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                        text-align: right;
                        .export-link{
                            cursor: pointer;
                            color: #2f8cff;
                            img{
                              height: 15px;
                            }
                        }
                    }
                    .invoice-card{
                        border: solid 1px #dfe3e6;
                        background-color: #f6f6f6;
                        padding: 12px 15px 20px;
                        margin-top: 15px;
                        span{
                            @include elementStyles(12px, #a3a3a3, normal, normal, $ibmplexRegular, null, null);
                            margin-bottom: 5px;
                        }
                        h5{
                            @include elementStyles(18px, #000000, normal, normal, $ibmplexRegular, null, null);
                            margin-bottom: 15px;
                        }
                        p{
                            @include elementStyles(12px, #000000, normal, normal, $ibmplexRegular, null, null);
                            &.error-class{
                                color: #d50000;
                            }
                            &.success-class{
                                color: #57a300;
                            }
                        }
                    }
                }
            }
            .export-list{
                float: right;
                margin-right: 10px;
                margin-top: 10px;
                .count-block{
                    span{
                        background: #2f8cff;
                        border-radius: 20px;
                        line-height: 12px;
                        padding: 1px 5px;
                        display: inline-block;
                        @include elementStyles(10px, #ffffff, normal, normal, $ibmplexRegular, null, null);
                        position: relative;
                        bottom: 2px;
                    }
                }
            }
            .asset-list-filter-sort{
                clear: both;
                margin-top: 35px;
                .add-filter-type{
                    border: 1px solid #dfe3e6;
                }
                .add-filter{
                    p{
                        padding: 6px 10px 5px;
                        @include elementStyles(12px, #8c9ba5, normal, normal, $ibmplexRegular, null, null);
                    }
                    .plus-icon{
                        width: 8px;
                        margin-right: 3px;
                        position: relative;
                        bottom: 2px;
                    }
                }
                .clear-filter{
                    @include elementStyles(12px, #8c9ba5, normal, normal, $ibmplexRegular, null, null);
                    letter-spacing: 0.2px;
                    float: right;
                    position: relative;
                    top: 8px;
                }
                .filter-block{
                    padding: 3px 0 2px;
                    h6{
                        font-family: $ibmplexRegular;
                    }
                }
            }
            .invoice-lists{
              cursor: pointer;
              .export-link{
                  @include elementStyles(14px, #2f8cff, normal, normal, $ibmplexRegular, null, null);
                  img{
                    height: 15px;
                  }
              }
                .header-row{
                    clear: both;
                    margin-top: 35px;
                    background-color: #dfe3e6;
                    padding: 10px 0 9px;
                    margin: 0;
                    h6, span{
                        @include elementStyles(12px, #8c9ba5, normal, normal, $ibmplexRegular, null, null);
                    }
                }
                .asset-list{
                    padding: 20px 15px;
                    h6, span{
                        @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                        .export-link{
                            @include elementStyles(14px, #2f8cff, normal, normal, $ibmplexRegular, null, null);
                            img{
                              height: 15px;
                            }
                        }
                    }

                }
                .total-amount{
                    @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                    margin-top: 20px;
                    text-align: right;
                    span{
                        @include elementStyles(24px, #000000, normal, normal, $ibmplexMedium, null, null);
                        display: inline-block;
                        margin-left: 40px;
                        span{
                          margin-left: 0px;
                        }
                    }
                }
            }
            .current-amount-due-cart{
              margin-top: 15px;
            }
            .current-amount-due{
                border-radius: 4px;
                background-color: #edfcdb;
                padding: 15px;
                margin-bottom: 15px;
                h3{
                    @include elementStyles(12px, #000000, normal, normal, $ibmplexRegular, null, null);
                    strong{
                      font-weight: bolder;
                    }
                }
                h5{
                    @include elementStyles(30px, #000000, normal, normal, $ibmplexRegular, null, null);
                }
                p{
                    margin-top: 5px;
                    @include elementStyles(12px, #4e4e4e, normal, normal, $ibmplexRegular, null, null);
                }
                a{
                    @include elementStyles(14px, #ffffff, normal, normal, $ibmplexRegular, null, null);
                    @extend %primary-botton;
                    padding: 8px 12px;
                }

                &.next-billing{
                    background-color: #ffffff;
                    padding: 5px;
                    margin-bottom: 0px;
                }
            }
            .billing-detail{
                background-color: #dfe4e9;
                padding: 15px;
                border-radius: 4px;
                margin-top: 15px;
                h3{
                    @include elementStyles(16px, #000000, normal, normal, $ibmplexMedium, null, null);
                    margin-bottom: 30px;
                    img{
                        width: 24px;
                        margin-right: 10px;
                        position: relative;
                        bottom: 2px;
                    }
                }
                p{
                    @include elementStyles(14px, #4e4e4e, normal, normal, $ibmplexRegular, null, null);
                }
            }
            .billing-detail-cart{
              margin-top: 0px!important;
              background: #fff;
              border: 1px solid #dfe4e9;
              box-shadow: 0 2px 4px 0 #e9e9e9;
              height: 380px;
              overflow: auto;
              &::-webkit-scrollbar{
                display: none;
              }
              .col-md-3{
                padding-left: 0px;
                padding-right: 0px;
              }
              h3{
                margin-bottom: 10px;
                background: #f1f0f0;
                border-radius: 3px;
                padding: 5px 5px 5px 5px;
              }
              .cart-detail-con{
                max-height: 290px;
                overflow: auto;
                &::-webkit-scrollbar{
                  display: none;
                }
                .invoice-preview-item{
                  width: 100%;
                  font-size: 12px;
                  margin-left: 15px;
                  margin-right: 15px;
                  margin-top: 5px;
                  border-bottom: 1px solid #e9e9e9;
                  .col-md-9{
                    padding-left: 0px;
                  }
                  .col-md-3{
                    padding-left: 0px;
                    padding-right: 0px;
                  }
                  &:last-child{
                    border-bottom: none;
                  }
                }
              }
            }
        }
        &.storage-container{
            min-height: 85vh;
            .billing-content-wrapper{
                padding-left: 70px;
                .storage-listing{
                    margin-top: 40px;
                    table{
                        thead{
                            border: solid 1px #dfe3e6;
                            background-color: #f0f2f3;
                            th{
                                @include elementStyles(12px, #8c9ba5, normal, normal, $ibmplexRegular, null, null);
                                padding: 5px 10px;
                            }
                        }
                        tr{
                          cursor: pointer;
                          &:hover{
                            box-shadow: 0 4px 4px -4px rgba(0,0,0,.61);
                          }
                          &.no-rcrd-found-tbody{
                              &:hover{
                                box-shadow: none;
                              }
                          }
                        }
                        td{
                            @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                            position: relative;
                            .asset-title{
                                padding-left: 40px;
                                img{
                                    width: 30px;
                                    position: absolute;
                                    left: 10px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    -webkit-transform: translateY(-50%);
                                    -moz-transform: translateY(-50%);
                                    -ms-transform: translateY(-50%);
                                    -o-transform: translateY(-50%);
                                }
                                p{
                                    @include elementStyles(16px, #000000, normal, normal, $ibmplexMedium, null, null);
                                }
                            }
                        }
                    }
                }
                .storage-status{
                    .progress{
                      border-radius: 13px;
                      border: 1px solid #05438a;
                      margin: 5px 0 0 0;
                      background-color: #c1deff;
                      height: 25px;
                      overflow: hidden;
                      .progress-bar{
                        background: #05438a;
                        border-radius: 13px;
                      }
                    }
                    .storage-usage-stat{
                      font-size: 12px;
                      color: #9013fe;
                      float: right;
                    }
                }
            }
        }
        &.notification-container{
            .billing-content-wrapper{
                padding-left: 70px;
            }
            .table-sticky {
              tbody{
                tr{
                  cursor: pointer;
                  td{
                    &:first-child{
                      text-transform: capitalize;
                    }
                    &:nth-child(3){
                      width: 20%;
                    }
                    &:last-child{
                      width: 10%;
                      font-size: 12px;
                      .mark-read{
                        color: #236ef5;
                      }
                    }
                  }
                  .read{
                    color: #969595;
                  }
                }
              }
            }
        }
        .switch-blk{
            float: right;
            position: relative;
            bottom: 35px;
            right: 10px;
            >span{
                @include elementStyles(12px, #a1a1a1, normal, normal, $ibmplexRegular, null, null);
                position: relative;
                bottom: 5px;
            }
            .switch{
                .slider{
                    background-color: #ffffff;
                    border: 1px solid  #a1a1a1;
                    height: 20px;
                    width: 46px;
                    position: relative;
                    top: -7px;
                    &::before{
                        background-color: #a1a1a1;
                        height: 14px;
                        width: 14px;
                        left: 3px;
                        bottom: 2px;
                    }
                }
                input:checked + .slider {
                    border: 1px solid  #2893ff;
                }
                input:checked + .slider:before {
                    background-color: #2893ff;
                }
            }
        }

    }

    .cart-container{
        margin: 0 -15px;
        background: #ffffff;
        padding: 0 15px;
        min-height: 96vh;
        .billing-content-wrapper{
            padding-left: 70px;
            .invoice-lists{
                .asset-list{
                    padding: 10px 15px 18px;
                    border-top: 1px solid #dfe3e6;
                    box-shadow: 0 2px 4px 0 #e9e9e9;
                    border-radius: 4px;
                    margin-bottom: 15px;
                    .label{
                      color: grey;
                      font-size: 12px;
                    }
                    .transaction{
                        position: relative;
                        padding-right: 12px;
                    }
                    .caret-list{
                        display: inline-block;
                        position: relative;
                        left: 8px;
                        top: 4px;
                        i{
                            display: block;
                            color: #2893ff;
                            line-height: 8px;
                            cursor: pointer;
                            font-size: 14px;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        .empty-cart{
            min-height: 85vh;
            position: relative;
            padding-left: 55px;

            img{
                width:200px;
            }

            >div{
               width: 100%;
               p{
                    @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                    margin-bottom: 30px;
               }
               a{
                    @extend %primary-botton;
                    @include elementStyles(14px, #ffffff, normal, normal, $ibmplexRegular, null, null);
                    padding: 15px 30px;
               }
            }
        }



    }

    .lessor-configure{
        margin-left: 70px;
        .lessor-title{
            margin-top: 15px;
            @include elementStyles(16px, #bd10e0, normal, normal, $ibmplexRegular, null, null);
            margin-bottom: 5px;
          }
          .block-subs{
            @include elementStyles(13px, #838383, normal, normal, $ibmplexRegular, null, null);
            margin-bottom: 15px;
          }
        .folder-row{
            margin-bottom: 15px;
            .folder-title{
              font-family: $ibmplexLight;
              color: #000000;
              font-size: 16px;
              margin-bottom: 20px;
              .switch-blk{
                  float: right;
                  position: relative;
                  right: 10px;
                  >span{
                      @include elementStyles(12px, #a1a1a1, normal, normal, $ibmplexRegular, null, null);
                      position: relative;
                      bottom: 5px;
                  }
                  .switch{
                      .slider{
                          background-color: #ffffff!important;
                          border: 1px solid  #a1a1a1!important;
                          height: 20px;
                          width: 46px;
                          position: relative;
                          top: -7px;
                          &::before{
                              background-color: #a1a1a1!important;
                              height: 14px;
                              width: 14px;
                              left: 3px;
                              bottom: 2px;
                          }
                      }
                      input:checked + .slider {
                          border: 1px solid  #2893ff!important;
                      }
                      input:checked + .slider:before {
                          background-color: #2893ff!important;
                      }
                  }
              }
            }
        .folder-card{
            background: #ffffff;
            padding: 15px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.22);
            margin-bottom: 20px;
            border-radius: 3px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            .downloadapp{
              position: absolute;
              top:0;
              bottom:0;
              right:0;
              left:0;
            }

            &:hover{
              .rec-download-icon{
              opacity:1;
              transition: 1s;
              }
              .rec-upload-icon{
                opacity:1;
                transition: 1s;
            }

            }


            .rec-upload-icon{
                position:absolute;
                bottom: 10px;
                right: 35px;
                top: 19px;
                width:20px;
                cursor:pointer;
                 input[type="file"]{
                position: absolute;
                width: 50px;
                opacity: 0;
                transform: translateX(-50%);
                cursor:pointer;
              }
              img{
                width: 20px;
                float: left;
              }
            }

            img{
              width: 30px;
              float: left;
              &.rec-download-icon{
                  top: 19px;
                  position:absolute;
                  bottom: 10px;
                  right: 10px;
                  width: 18px;
                  opacity:1;
              }
            }


            .folder-info{
              margin-left: 40px;
              h4{
                font-family: $ibmplexRegular;
                color: #000000;
                font-size: 16px;
              }
              p{
                font-family: $ibmplexRegular;
                color: #8C9BA5;
                font-size: 12px;
              }
            }
          }
        }



    .lessor-profile{
        margin-bottom: 20px;
        margin-right: 5px;
        border-radius: 3px;

        .edit-profile-icon{
            float: right;
            position: relative;
            top: 15px;
            right: 15px;
            cursor: pointer;
          }


        form{
          background: #fff;
          box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
        }
        .lessor-detail{
          padding:25px 0;
          margin: 0 0px 15px;

          .form-group{
            margin-bottom: 30px;
          }
          .form-group-edit{
            margin-bottom: 10px;
          }
          label{
            display: block;
          }
          input[type="text"], input[type="password"]{
            border-bottom: 1px solid #8e8e8e;
            width: 100%;
            color: #000;
          }
         textarea{
            border: 1px solid #8e8e8e;
            width: 100%;
            border-radius: 3px;
            padding: 5px;
            height: 50px;
            margin-top: 2px;
            color: #000;
          }
          .update-img-block{
            width: 150px;
            position: relative;

            img{
              width: 150px;
              margin-bottom: 15px;
              &.profile-pic{
                width: 150px;
                margin-bottom: 5px;
                object-fit: contain;
                margin-left: 25px;
                height:120px;
              }
              &.edit-img{
                position: absolute;
                right:4px;
                top:4px;
                width:14px;
                background: rgba(255,255,255,0.4);
                border-radius: 2px;
                padding: 3px;
              }
            }

            h4{
              color:#2f8cff;
              font-size: 12px;
              text-align: center;
              width: 150px;
              margin-left: 25px;
              position: relative;
              cursor:pointer;
              input[type="file"]{
                position: absolute;
                width: 100px;
                opacity: 0;
                transform: translateX(-50%);
                cursor:pointer;
              }
            }

          }


        }
        .submit-block{
          padding: 15px;
          background: #f0f2f3;
          input[type="submit"]{

          }
          a{
          cursor: pointer;
          font-family:$ibmplexRegular;
          font-size: 12px;
          color: #2f8cff;
          margin-left:15px;
          &.deactivate-link{
            padding:7px;
            color: #fe6465;
          }
          &.resend-link{
            padding:7px;

          }
          }

        }

      }

       .folder-card{
      background: #ffffff;
      padding: 15px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.22);
      margin-bottom: 20px;
      border-radius: 3px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
        &.owner-card{
            cursor: default;
        }
      .downloadapp{
        position: absolute;
        top:0;
        bottom:0;
        right:0;
        left:0;
      }

      &:hover{
        .rec-download-icon{
        opacity:1;
        transition: 1s;
        }
      }

      img{
        width: 30px;
        float: left;
        &.rec-download-icon{
            position:absolute;
            bottom: 10px;
            right: 10px;
            width:15px;
            opacity:0;

        }
      }

      .folder-info{
        margin-left: 40px;
        h4{
          font-family: $ibmplexRegular;
          color: #000000;
          font-size: 16px;
        }
        p{
          font-family: $ibmplexRegular;
          color: #8C9BA5;
          font-size: 12px;
        }
        &.owner-info{
          margin-left: auto;
        h4{
          color: #a0a0a0;
          font-size: 14px;
          margin-bottom: 2px;
        }
        p{
          margin-bottom: 10px;
          color: #000000;
        }
        }
      }
    }
}

    .licenses-modal{
        padding: 20px 50px;
        h2{
            @include elementStyles(16px, #bd10e0, normal, normal, $ibmplexRegular, null, null);
            margin-bottom: 15px;
        }
        p{
            @include elementStyles(12px, #404040, 18px, normal, $ibmplexRegular, null, null);
            margin-bottom: 15px;
        }
        div{
            padding: 10px 20px;
            background-color: #cce3f1;
            p{
                color: #000000;
                margin-bottom: 0;
            }
            margin-bottom: 15px
        }
        a{
            @extend %primary-botton;
            padding: 5px 15px;
            font-weight: 400;
        }
    }
    #paymentFailed{
        img{
            height: 50px
        }
        h4{
            @include elementStyles(18px, #bd10e0, normal, normal, $ibmplexRegular, null, null);
            margin: 10px 0;
        }
        p{
            @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
        }
        a{
            @include elementStyles(14px, #ffffff, normal, normal, $ibmplexRegular, null, null);
            @extend %primary-botton;
            margin: 10px 0;
            display: inline-block;
            padding: 5px 10px;
            margin: 15px 5px 10px;
            &.cancel{
                color: #ffffff;
                background: #2f8cff;
                @extend %secondary-button;
            }
        }
    }
}

@media(max-width: 767px){
    body{
        .onboarding-outer-wrapper{
            height: auto !important;
            .logo{
                width: 45px;
            }
            .sign-in-btn{
                a{
                    margin-left: 5px !important;
                    margin-right: 5px !important;
                    &.ternary-button{
                        padding: 6px 6px !important;
                    }

                }
                >span{
                    display: none;
                }
                .ternary-button{
                    margin-left: 0;
                }
            }
            .hero-row{
                height: auto !important;
            }
            .hero-text-wrapper{
                margin-top: 0;
            }
            .form-wrapper{
                .signup-form{
                    margin-top: 15px;
                }
            }
        }
        .features-wrapper{
            padding-top: 40px!important;
            .feature-content-block{
                .tab-content {
                    .background-difference{
                        margin: 0 !important;
                        padding: 40px 0px !important;
                    }
                    ul{
                        margin-bottom: 0;
                    }
                }
            }
        }
        .pricing-blk-wrapper{
            .feature-content-block{
                .lessor-features{
                    margin-left: 0 !important;
                    .card-block{
                        margin: 15px;
                    }
                }
            }
            .wrapper{
                position: relative !important;
                transform: none !important;
                left: 0 !important;
                bottom: 0 !important;
                padding-bottom: 25px;
            }
        }
        footer{
            padding-top: 30px;
            .col-6{
                margin-bottom: 15px;
            }
        }
        .terms-conditions-sections{
            width: 100%;
            padding: 30px 15px 60px;
        }
    }
}

@media(min-width: 768px) and (max-width: 992px){
     body{
         .pricing-blk-wrapper{
            .feature-content-block{
                .lessor-features{
                    margin-left: 0 !important;
                    .card-block{
                        margin: 0;
                        padding-bottom: 100px;
                    }
                }
            }
        }
        .signup-form{
            .nav-tabs{
                li{
                    max-width: 100%;
                    label{
                        input{
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
        .features-wrapper{
            .feature-content-block{
                .tab-content {
                    .background-difference{
                        margin: 0 !important;
                        padding: 40px 0px !important;
                    }
                    ul{
                        margin-bottom: 0;
                    }
                }
            }
        }
     }
}
.blog-card {
    border-radius: 5px;
    width: 350.5px;
    height: 520px;
    margin-bottom: 30px;
    box-shadow: 10px 10px 300px 12px rgba(230,230,230,1);

     img{
        width:350.5px;
        height: 232px;
        position: relative;
        top: -20px;
        right: 21px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
   .blog-card-title {
       font-size: 15px !important;
       height: 57px !important;
       text-decoration: none !important;
       position: relative ;
       top: -7px;
       font-weight: 600;
       color: #000;
   }
   .blog-card-text {
       font-size: 13px !important;
       height: 95px !important;
       position: relative;
       top: -4px;
   }
   .view-more-news-feed {
     position: absolute;
     font-size: 12px;
     bottom: 20px;
     right: 27px;
   }
}
