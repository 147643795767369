@import '/setting';
.MuiChip-colorPrimary{
  background: #adb8f8 !important;
}
.mui-table-format{
  &.hovered-table{
    tr{
      background: inherit;
      cursor: pointer;
    }
  }
  .actions-cell{
    text-align: right;
    svg{
      cursor: pointer;
    }
  }
  thead{
    background: #B5BEDB;
    th{
      padding: 5px 10px;
      background: #B5BEDB;
      @include elementStyles($size: $font13, $color:rgba(0,0,0), $fontFamily: $ibmplexMedium, $weight:500);
      span{
        @include elementStyles($size: $font13, $color:rgba(0,0,0), $fontFamily: $ibmplexMedium, $weight:500);
        &.MuiTableSortLabel-root{
          &:hover{
            color: #000;
            .MuiTableSortLabel-icon{
              color: #000 !important;
            }
          }
          .MuiTableSortLabel-icon{
            opacity: 0.5 !important;
          }
        }
        &.MuiTableSortLabel-active{
          color: #496ac5;
          .MuiTableSortLabel-icon{
            color: #496ac5 !important;
            opacity: 1 !important;
          }
        }
      }
    }
  }
  tbody{
    tr{
      &:nth-child(odd){
        background: #fff;
      }
      &:nth-child(even){
        background: #E6EBFB;
      }
      &.Mui-selected{
        background: rgba(245, 0, 87, 0.08);
      }
      td{
        word-break: break-word;
        padding: 5px 10px;
        @include elementStyles($size: $font15, $color:$black, $fontFamily: $ibmplexRegular, $weight:400);
      }
    }
  }
}