@import '../../../assets/styles/setting';
.gray-info-box{
    font-size: 12px;
    margin-top: 20px;
    border: 1px solid #353A4A;
    border-radius: 3px;
    padding: 10px;
    background: #DFE4E9;
    width: 100%;
}
.portfolio-block{
  padding: 30px 20px;
  h4{
    font-family: $ibmplexRegular;
    font-size: 20px;
    color: $color_000;
    margin-bottom: 24px;
  }

}




  .outer-wrapper{
    margin-bottom: 15px;
    .first-block{
      width:10%;
      .height-block{
        height:100%;
        position: relative;
        img{
          width: 27px;
        }
        .vl{
          border-left: 1px solid #8b36e1;
          bottom:-17px;
          display: inline-block;
          position: absolute;
          left: 30%;
          top: 25px;
          transform: translateX(-50%);
        }
      }
    }
    .scnd-block{
      width:90%;
      .shadow-block{
        background:$color_fff;
        box-shadow:0 4px 16px 0 #e6e6e6;
        border-radius:6px;
        padding:6px;
        h6{
          font-family:$ibmplexRegular;
          font-size:12px !important;
          color:#929498 !important;
        }
        p{
          font-family:$ibmplexRegular;
          font-size:12px;
          color:$color_000;
        }
        span{
          font-family:$ibmplexRegular;
          font-size:10px;
          color:$color_000;
        }
       .show-data{
         cursor:pointer;
        span{
          background: transparent;
          border: 0;
          font-family: $ibmplexRegular;
          font-size: 10px;
          color: #4a90e2;
          i{
            font-size: 7px;
            font-style: normal;
            margin-left: 3px;
            display:inline-block;
          }
        }
        .edit-detail-table{
          overflow: auto;
          &::-webkit-scrollbar{
            display: none;
          }
          table{
            width: 100%;
            border-collapse: separate;
            thead{
              th{
                padding:4px 20px;
                font-family: $ibmplexRegular;
                font-size:12px;
                color:#a0a0a0;
                border:1px solid #ccc;
                width:80px !important;
              }
            }
            tbody{
              th{
                padding:4px 20px;
                font-family: $ibmplexRegular;
                font-size:12px;
                color:#a0a0a0;
                border:1px solid #ccc;
                width:80px !important;
              }
              td{
                width:80px !important;
                padding:4px 20px;
                font-family: $ibmplexRegular;
                font-size:12px;
                color:$color_000;
                border:1px solid #ccc;
                width:80px !important;
                span{
                  color:$color_000 !important;

                }

              }
            }
          }
        }


       }
      }
      .sec-inner-frst-block{
        width:50%;
      }
      .sec-inner-sec-block{
        width:50%;
      }
    }
  }

  #buyLicenseModal{
    .modal-content{
      max-height: 550px;
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
    }
    .block-title{
      @include elementStyles(16px, #000000, normal, normal, $ibmplexRegular, null, null);
      border-bottom: 1px solid #dddddd;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    .close-btn{
      display: inline-block;
      width: 12px;
      position: absolute;
      right: 16px;
      top: 20px;
      z-index: 99;
      cursor: pointer;
    }
    .contact-sales-grid{
      display: none;
    }
    .users-license{
      margin-bottom: 5px;
      .pricing-outer{
          margin: 0;
          padding: 20px 5px;
          width: 100%;
          margin-bottom: 0px;
          .pricing-grid{
              border-right: 1px solid #dddddd;
          }
          .switcher{
              li{
                  @include elementStyles(12px, #000000, normal, normal, $ibmplexRegular, null, null);
                  .switch{
                      .slider{
                          background-color: #ffffff;
                          border: 1px solid  #2893ff;
                          height: 26px;
                          width: 52px;
                          position: relative;
                          top: -7px;
                          &::before{
                              background-color: #2893ff;
                              height: 18px;
                              width: 18px;
                              left: 3px;
                              bottom: 3px;
                          }
                      }
                  }
                  &:last-child{
                      margin-left: 12px;
                  }
              }
          }
          .price{
              @include elementStyles(40px, #000000, normal, normal, $ibmplexRegular, null, null);
              margin-top: 25px;
              span{
                  @include elementStyles(12px, #898989, normal, normal, $ibmplexRegular, null, null);
                  margin: 5px 0;
                  display: block;
                  &:first-child{
                    color: #000;
                    display: inline-block;
                    font-size: 35px;
                  }
              }
          }
          .users-grid{
              padding-left: 25px;
              .pricing-calculations{
                  li{
                      @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                      &:first-child{
                          @include elementStyles(14px, #000000, normal, normal, $ibmplexMedium, null, null);
                      }
                  }
                  .caret-list{
                      position: relative;
                      top: 4px;
                      i{
                          display: block;
                          color: #2893ff;
                          line-height: 10px;
                          cursor: pointer;
                      }
                  }
              }
              .total-price{
                  margin: 15px 0;
                  vertical-align: middle;
                  li{
                      vertical-align: top;
                      &:first-child{
                          @include elementStyles(20px, #62b305, normal, normal, $ibmplexRegular, null, null);
                      }
                      &:last-child{
                          @include elementStyles(12px, #9d9d9d, normal, normal, $ibmplexRegular, null, null);
                      }
                  }
              }
              .buy-license{
                  @extend %primary-botton;
                  padding: 8px;
                  @include elementStyles(14px, #ffffff, normal, normal, $ibmplexRegular, null, null);
                  display: inline-block;
              }
          }
      }
      .contact-sales{
          border: 1px solid #dddddd;
          box-shadow: 0 2px 4px 0 rgba(233, 233, 233, 0.5);
          border-radius: 6px;
          margin: 0;
          padding: 20px 0;
          align-items: center;
          margin-bottom: 0px;
          img{
              position: absolute;
              left: 15px;;
              top: 50%;
              transform: translateY(-50%);
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
              width: 50px;
          }
          .sales-content{
              padding-left: 70px;
              p{
                  @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                  margin-bottom: 1px;
              }
              a{
                  @include elementStyles(14px, #2893ff, normal, normal, $ibmplexRegular, null, null);
              }
          }
      }
      &.mr-calculator{
          .pricing-outer{
            border: 1px solid #ececec;
            border-radius: 8px;
            margin: 10px 0;
          }
          .mr-pricing-grid{
              display: flex;
              display: -webkit-flex;
              flex-flow: wrap;
              -webkit-flex-flow: wrap;
              &:before,&:after{
                  width: 0;
              }
              .pricing-grid{
                  >div{
                      width: 100%;
                  }
                  .title{
                      position: relative;
                      vertical-align: middle;
                      @include elementStyles(18px, #000000, 18px, normal, $ibmplexRegular, null, null);
                      >span{
                        display: inline-block;
                        margin-bottom: 10px;
                        vertical-align: middle;
                        &:first-child{
                          position: absolute;
                          left: 0;
                          top: 35%;
                          transform: translateY(-50%);
                          -webkit-transform: translateY(-50%);
                          -moz-transform: translateY(-50%);
                          -ms-transform: translateY(-50%);
                        }
                        &:last-child{
                          font-size: 15px;
                          padding-left: 40px;
                          text-align: left;
                        }
                      }
                      img{
                          width: 30px;
                          display: inline-block;
                          vertical-align: middle;
                          margin-right: 5px;
                      }
                      >div{
                          display: inline-block;
                          vertical-align: middle;
                          text-align: left;
                          span{
                              @include elementStyles(12px, #898989, normal, normal, $ibmplexRegular, null, null);
                              margin-top: 5px;
                              display: block;
                              text-align: left;
                          }
                      }
                      &.text-left{
                        span{
                          padding-left: 0;
                          padding-top: 5px;
                          position: static;
                          transform: unset;
                        }
                      }
                  }
                  .price{
                      margin-top: 15px;
                      font-size: 36px;
                      span{
                        &:first-child{
                          color: #000;
                          display: inline-block;
                          font-size: 35px;
                        }
                      }
                  }
              }
              .flex-end{
                  align-items: flex-end;
              }
          }
      }
    }
}
